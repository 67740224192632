import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function LongBayRegionalPark() {
    return (
        <section className="longbayregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default LongBayRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Long Bay Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPsYZtLu3JcmeREwPrB7ETLJN3xiCainal0mszy=s1360-w1360-h1020";
export const photocredits = "Damian Wilson";
export const url = "long-bay-regional-park";
export const location = "https://www.google.com/maps/dir//Long+Bay+Regional+Park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d0d3acf38938197:0xf00ef62249c9fa0?sa=X&ved=2ahUKEwjJv4eF_N2CAxWASWwGHcTDCuMQ9Rd6BAhYEAA";
export const address = "Beach Road, Long Bay, Auckland 0792";
export const website = "";
export const cordinates = [-36.6893967162143, 174.75092835196094];