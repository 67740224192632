import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.scrollY > 50) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div className="fixed bg-pink bottom-3 right-3 cursor-pointer">
            {
            isVisible && (
                <button
                    onClick={scrollToTop}
                    className="btn btn-outline-secondary scroll-to-top rounded-circle"
                >
                    <FontAwesomeIcon icon={faChevronUp} />
                </button>
            )
            
            }
        </div>
    );
}

export default ScrollToTop;