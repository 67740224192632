import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Wenderholm() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Wenderholm;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Wenderholm Regional Park";
export const shortParagraph = "Leafy coastal area featuring facilities such as trails, a campground, a boat ramp & beaches.";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPvHR1gVk-vOKEC6S_uie1XEZX09d4T_05LJZWm=s1360-w1360-h1020";
export const photocredits = "Ashi Ezz";
export const url = "wenderholm-regional-park";
export const location = "https://www.google.com/maps/place/Wenderholm+Regional+Park/@-36.5334542,174.6993075,13.6z/data=!4m15!1m8!3m7!1s0x6d0d27200792657f:0xf00ef62249c9900!2sWenderholm+Regional+Park!8m2!3d-36.5321041!4d174.7114684!10e1!16s%2Fm%2F0h3x86z!3m5!1s0x6d0d27200792657f:0xf00ef62249c9900!8m2!3d-36.5321041!4d174.7114684!16s%2Fm%2F0h3x86z?entry=ttu";
export const address = "37 Schischka Road, Waiwera 0873";
export const website = "";
export const cordinates = [-36.507212417434175, 174.70166149242374];