import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function LakeTekapoRegionalPark() {
    return (
        <section className="tekaporegionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default LakeTekapoRegionalPark;

export const tags = ["regionalpark", "canterbury", "south-island"];
export const title = "Lake Tekapo Regional Park";
export const shortParagraph = "Lake Tekapo Regional Park is situated on the east side of Lake Tekapo in New Zealand. The park offers an extensive network of shared walking and cycling tracks, including lakeside walks and winding trails.";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipMa2ieiOHMAA9H0BRZk7UzHUfCcazY308AwCkWR=s1360-w1360-h1020";
export const photocredits="Shamlal Shaji";
export const url = "lake-tekapo-regional-park";
export const location = "https://www.google.com/maps/dir//lake+tekapo+regional+park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d2b75810a47ab3b:0x3666025f90067b6b?sa=X&ved=2ahUKEwi21t6f4sKCAxWJxjgGHdLSDysQ9Rd6BAhYEAA&sqi=2";
export const address = "Lilybank Road, Tekapo 7999";
export const website = "";
export const cordinates = [-44.001916730342515, 170.50293813698647];