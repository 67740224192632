import React, { useState } from "react";
import DestinationBanner from "../inc/DestinationBanner";
import DestinationNavbar from "../inc/DestinationNavbar";
import AucklandInfo from "../cityInfo/AucklandInfo";
import AucklandAttractions from "../see_n_do/AucklandAttractions";
import AucklandEvents from "../events/AucklandEvents";
import AucklandStays from "../accomodation/AucklandStays";
import AucklandTransport from "../travel/AucklandTransport";
import AucklandDeals from "../deals_n_packages/AucklandDeals";

function Auckland() {
    const [activeNavItem, setActiveNavItem] = useState("all");

    const handleActiveItem = (item) => {
        setActiveNavItem(item);
    };

    const componentMap = {
        all: (
            <React.Fragment>
                <AucklandInfo />
                <AucklandAttractions />
                {/* <AucklandEvents />
                <AucklandStays />
                <AucklandTransport />
                <AucklandDeals /> */}
            </React.Fragment>
        ),
        city_info: <AucklandInfo />,
        see_n_do: <AucklandAttractions />,
        // events: <AucklandEvents />,
        // accommodation: <AucklandStays />,
        // transport: <AucklandTransport />,
        // deals_and_packages: <AucklandDeals />
    };

    return (
        <section>
            <DestinationBanner banner="https://inafarawayland.com/wp-content/uploads/2021/11/Auckland-5.jpg"
                destination="Auckland" />
            <div className="container p-3">
                <DestinationNavbar
                    activeNavItem={activeNavItem}
                    handleActiveItem={handleActiveItem}
                />
                {componentMap[activeNavItem]}
            </div>


        </section>
    );
}

export default Auckland;