import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function WaitawaRegionalPark() {
    return (
        <section className="waitawaregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default WaitawaRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Waitawa Regional Park";
export const shortParagraph = "Waitawa Regional Park is a regional park situated on the coast of Tāmaki Strait, east of Auckland, New Zealand. It is located in Franklin in the Auckland Region, near Kawakawa Bay and Clevedon, about 50 kilometres from central Auckland.";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipMA0O1NaeJHcszChW-4dA006-NkZ2HY4truLhmB=s1360-w1360-h1020";
export const photocredits="Josephine Lucas De Guzman";
export const url = "waitawa-regional-park";
export const location = "https://www.google.com/maps/dir//Waitawa+Regional+Park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d72b987003239b9:0x7fb6a38fd0b70e08?sa=X&ved=2ahUKEwjQpYXRwsKCAxX1sVYBHVxhDnUQ9Rd6BAhREAA";
export const address = "1168 Clevedon-Kawakawa Road, Kawakawa Bay 2585";
export const website = "";
export const cordinates = [-36.93801496577325, 175.1412106808101];