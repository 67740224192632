import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function WaharauRegionalPark() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default WaharauRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Waharau Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipNf5vIkmHabeAyjfKKuGhKiLP0Cq-oWfBDGvUyf=s1360-w1360-h1020";
export const photocredits = "Shona Newton";
export const url = "waharau-regional-park";
export const location = "https://www.google.com/maps/place/Waharau+Regional+Park/@-37.0393473,175.2920593,17z/data=!3m1!4b1!4m6!3m5!1s0x6d729819233fcc2b:0x2e12e9019ee794e3!8m2!3d-37.0393516!4d175.2946342!16s%2Fg%2F11b6hfwhm9?entry=ttu";
export const address = "1748 East Coast Road, Whakatīwai 2473";
export const website = "";
export const cordinates = [-37.03917172811446, 175.29469856917206];