import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function AucklandBotanicGardens() {
    return (
        <section className="aucklandbotanicgardens">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default AucklandBotanicGardens;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Auckland Botanic Gardens";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipM8PWbPapumdEuHJV-80dKrd3Qqw9BABhQv7R1G=s1360-w1360-h1020";
export const photocredits = "Amar Deo";
export const url = "auckland-botanic-gardens";
export const location = "https://www.google.com/maps/dir//Auckland+Botanic+Gardens/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d0d4d7bccbfac79:0xf00ef62249db5b0?sa=X&ved=2ahUKEwiA99acpdyCAxUiZvUHHWzKBJMQ9Rd6BAhREAA";
export const address = "102 Hill Road, The Gardens, Auckland 2105";
export const website = "";
export const cordinates = [-37.012636365287335, 174.9077138961568];