import React from "react";
import { Link } from "react-router-dom";

function DestinationNavbar(props) {

    return (
        <nav className="navbar navbar-expand-lg navbar-dark border-body sticky-top text-white shadow near-moon">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#destinationNavbarContent"
                    aria-controls="destinationNavbarContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="destinationNavbarContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className={`nav-link ${props.activeNavItem === "all" ? "active" : ""}`} aria-current="page"
                                onClick={() => props.handleActiveItem("all")}
                                href="#">
                                All
                            </a>
                            {/* <button
                                className={`nav-link ${props.activeNavItem === "all" ? "active" : ""}`}
                                onClick={() => props.handleActiveItem("all")}
                            >
                                All
                            </button> */}
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${props.activeNavItem === "city_info" ? "active" : ""}`} aria-disabled="true"
                                onClick={() => props.handleActiveItem("city_info")}
                                // href="/destinations/newzealand/north-island/auckland/city-info"
                                >
                                City Info
                            </a>
                            {/* <button
                                className={`nav-link ${props.activeNavItem === "city_info" ? "active" : ""}`}
                                onClick={() => props.handleActiveItem("city_info")}
                            >
                                City Info
                            </button> */}
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${props.activeNavItem === "see_n_do" ? "active" : ""}`} aria-disabled="true"
                                onClick={() => props.handleActiveItem("see_n_do")}
                                // href="/destinations/newzealand/north-island/auckland/see-n-do"
                                >
                                See & Do
                            </a>
                            {/* <button
                                className={`nav-link ${props.activeNavItem === "see_n_do" ? "active" : ""}`}
                                onClick={() => props.handleActiveItem("see_n_do")}
                            >
                                See & Do
                            </button> */}
                        </li>
                        {/* <li className="nav-item">
                            <a className={`nav-link ${props.activeNavItem === "events" ? "active" : ""}`} aria-disabled="true"
                                onClick={() => props.handleActiveItem("events")}
                                href="/destinations/newzealand/north-island/auckland/events"
                                >
                                Events
                            </a> */}

                            {/* <button
                                className={`nav-link ${props.activeNavItem === "events" ? "active" : ""
                                    }`}
                                onClick={() => props.handleActiveItem("events")}
                            >
                                Events
                            </button> */}
                        {/* </li> */}
                        {/* <li className="nav-item">
                            <button
                                className={`nav-link ${activeNavItem === "transport" ? "active" : ""
                                    }`}
                                onClick={() => handleActiveItem("transport")}
                            >
                                Transport
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeNavItem === "accommodation" ? "active" : ""
                                    }`}
                                onClick={() => handleActiveItem("accommodation")}
                            >
                                Accommodation
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeNavItem === "deals_and_packages" ? "active" : ""
                                    }`}
                                onClick={() => handleActiveItem("deals_and_packages")}
                            >
                                Deals and Packages
                            </button>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav >
    );
}

export default DestinationNavbar;