import React from "react";
import AucklandCity from "../../assets/images/city-info/pexels-liam-spicer-5342978.jpg";


function AucklandAttractions() {
    return (
        <section className="city-info">
            <div className="row">
                <h1>See and Do</h1>

            </div>
            <div className="row">
                <h1>Food and Wine</h1>

            </div>
            <div className="row">
                <h1>Food and Wine</h1>
                <p>Vineyards</p>

            </div>
            <div className="row">
                <h1>Love the Sea?</h1>

            </div>
            <div className="row">
                <h1>Hiking</h1>

            </div>
            <div className="row">
                <h1>Gardens and Parks</h1>

            </div>
            <div className="row">
                <h1>Regional Parks & Reserves</h1>
                <p>Auckland, New Zealand, is renowned for its stunning landscapes and natural beauty, and a significant part of this is attributed
                    to its diverse regional parks and reserves. These spaces offer a retreat from the urban hustle, providing an opportunity to
                    connect with nature and engage in various recreational activities.
                </p>
                <p>Getting there/top points</p>
                <p>Location</p>
                <p>Tips</p>
                <p>Got more time? Visit Nearby places</p>

            </div>
            <div className="row">
                <h1>Art and Culture</h1>
                <p>Museum</p>
                <p>Art Gallery</p>
                <p>Maori Cultural Performances</p>
                <p>Pottery</p>
                <p>Planetarium</p>
            </div>
            <div className="row">
                <h1>Activity</h1>
                <p>Horse Riding</p>
                <p>Hot Springs</p>
                <p>Sailing</p>
                <p>Scuba Diving</p>
                <p>Skiing/ Snowboarding</p>
                <p>Sky Diving</p>
                <p>Snorkelling</p>
                <p>Surfing</p>
            </div>
            <div></div>

        </section>
    )
}

export default AucklandAttractions;