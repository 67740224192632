import React from "react";
import Cherry from "../assets/images/pexels-brett-sayles-992734.jpg";

function SpringRetreats() {
    return (
        <section className="springretreats mb-5">
            <div className="text-white text-center p-5"
                style={{
                    background: `url(${Cherry})`, backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", backgroundPosition: "center"
                }}>
                <div className="col-lg-6 p-5 col-12 text-white" style={{ border: "4px solid #fff", backgroundColor: "#000", opacity: "0.6" }}>
                    <h1 className="spring-text">Spring Retreats</h1>
                    <p className="p-3 disable-text-selection">
                        As winter's grip loosens, New Zealand awakens in a burst of color and vitality during springtime.
                        This season, spanning from September to November, is a breathtaking display of nature's rebirth.
                    </p>
                    <a className="btn spring-btn fw-bold" href="/destinations/newzealand/travel-guide/newzealand-cherry-blossom-2023" role="button">
                        Explore Locations
                    </a>
                </div>
            </div>
        </section>
    );
}

export default SpringRetreats;