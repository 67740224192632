import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function OmanaRegionalPark() {
    return (
        <section className="omanaregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default OmanaRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Ōmana Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPAFHBK7T6eEsEwBvQmHMOR0Q1Wl80rvXQb0u-X=s1360-w1360-h1020";
export const photocredits = "Amanda Pope";
export const url = "omana-regional-park";
export const location = "https://www.google.com/maps/place/Omana+Regional+Park/@-36.8794009,175.0230584,17z/data=!3m1!4b1!4m6!3m5!1s0x6d72b5d87103d699:0xf00ef62249ccdb0!8m2!3d-36.8794052!4d175.0256333!16s%2Fm%2F03mhtvt?entry=ttu";
export const address = "2 Omana Beach Road, Maraetai 2571";
export const website = "";
export const cordinates = [-36.87919920393156, 175.02572985567016];