import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function PakiriRegionalPark() {
    return (
        <section className="pakiriregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default PakiriRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Pākiri Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMvClcllgPi4teulXoDrXy9J-aHSsuCSPxCISgx=s1360-w1360-h1020";
export const photocredits = "Pakiri Beach Holiday Park";
export const url = "pakiri-regional-park";
export const location = "https://www.google.com/maps/dir/-36.9159618,174.8046436/-36.26284,174.75012/@-36.2843867,174.3872262,10z/data=!4m4!4m3!1m1!4e1!1m0?entry=ttu";
export const address = "M Greenwood Road, Auckland 0972";
export const website = "";
export const cordinates = [-36.23954053001891, 174.7497750325726];
export const alerts="The regional park cannnot be accessed from Pakiri River Road. Use the address";