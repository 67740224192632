import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Whakanewha() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Whakanewha;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Whakanewha Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOYqa8icQC3YNkIomN717yrqE9j2ygX00W9zgFv=s1360-w1360-h1020";
export const photocredits = "Eranda Ranasinghe";
export const url = "whakanewha-regional-park";
export const location = "https://www.google.com/maps/place/Whakanewha+Regional+Park/@-36.8216853,175.0742724,17z/data=!3m1!4b1!4m6!3m5!1s0x6d72c845367db883:0xf00ef62249cb740!8m2!3d-36.8216896!4d175.0768473!16s%2Fg%2F1thvygkc?entry=ttu";
export const address = "75 Gordons Road, Waiheke Island, Auckland 1971";
export const website = "";
export const cordinates = [-36.821569333393164, 175.07689021148886];