import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Mutukaroa() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Mutukaroa;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Mutukaroa / Hamlins Hill Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMyhGQPlLfw8C9BhEVNqPYHKkXMRJTLwPpUmG_4=s1360-w1360-h1020";
export const photocredits = "Liam Gallagher";
export const url = "mutukaroa-regional-park";
export const location = "https://www.google.com/maps/place/Mutukaroa+%2F+Hamlins+Hill+Regional+Park/@-36.9198921,174.8314209,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0d4936e50080d7:0xf00ef62249ce330!8m2!3d-36.9198964!4d174.8339958!16s%2Fg%2F1th5750w?entry=ttu";
export const address = "1014 Great South Road, Penrose, Auckland 1060";
export const website = "";
export const cordinates = [-36.91975913291604, 174.83399579615164];