import React, { useState } from "react";

function MissionVision() {
    return (
        <section className="missionvision">
            <div className="container p-5">
                <div className="row">
                    <div className="col-lg-4 mb-3">
                        <div className="card text-center h-100">
                            <div className="card-body p-5 jack-dorseys-stock text-white">
                                <h1 className="card-title">Mission</h1>
                                <p className="card-text text-center mt-5">
                                    Our mission is to share the unparalleled beauty and culture of New Zealand with the world.
                                    We are passionate about creating unforgettable travel experiences that immerse you in the
                                    natural wonders, rich history, and vibrant communities of Aotearoa.
                                </p>
                                {/* <p className="card-text text-center mt-2">
                                Join us on a voyage through New Zealand, where every day brings new discoveries and unforgettable moments. 
                                Let us be your guide to the extraordinary beauty of this land, and together, 
                                we'll create memories that will last a lifetime.
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="card text-center h-100">
                            <div className="card-body p-5 jack-dorseys-stock text-white">
                                <h1 className="card-title">Vision</h1>
                                <p className="card-text text-center mt-5">
                                    Our vision is to become the leading gateway to the enchanting world of New Zealand.
                                    We aspire to be the go-to resource for travelers seeking immersive experiences that
                                    reveal the hidden gems, natural wonders, and diverse cultures of Aotearoa.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="card text-center h-100">
                            <div className="card-body p-5 jack-dorseys-stock text-white">
                                <h1 className="card-title">Values</h1>
                                <p className="card-text text-center mt-5">
                                    We promote responsible and authentic travel experiences in New Zealand,
                                    respecting its culture and environment while fostering a sense of community among travelers.
                                    We are committed to excellence, inclusivity, and sustainability.
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );
}

export default MissionVision;