import React from "react";
import January from "./months/January";
import February from "./months/February";
import March from "./months/March";
import April from "./months/April";
import May from "./months/May";
import June from "./months/June";
import July from "./months/July";
import August from "./months/August";
import September from "./months/September";
import November from "./months/November";
import December from "./months/December";
import October from "./months/October";
import Background from "../assets/images/pexels-jarod-barton-4863998.jpg"

function Calendar2024() {
    function printFunction() {
        // window.print();
        var divContents = document.getElementById("calendar").innerHTML;
        var prnt = window.open('', '', '');
        // var prnt = window.open('', '', 'height=500, width=500');
        prnt.document.write(divContents);
        prnt.document.close();
        prnt.print();
    }

    return (
        <section className="calendar2024">
            <div className="container mt-5">
                <div className="row">
                    <button className="btn btn-outline-secondary" onClick={printFunction}>Print</button>
                </div>
            </div>

            <div className="container my-5 shadow" id="calendar">
                <div className="row mb-5" style={{backgroundColor:"#087EA6", height: "500px", position:"relative"}}>
                    <img src={Background} 
                    className="pt-5 px-5" alt="New Zealand Regional Parks" 
                    style={{ pointerEvents: "none", height: "550px", width:"100%", position:"absolute", top: 0, left: 0  }} />
                </div>
                
                <div className="row" style={{marginTop:"80px"}}>
                    <div className="col-4">
                        {/* <div className="month-header-container d-flex"> */}
                            <h3 className="blue-text text-center">JANUARY</h3>
                            {/* <div className="blue-block"></div> */}
                        {/* </div> */}
                        <January />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">FEBRUARY</h3>
                        <February />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">MARCH</h3>
                        <March />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <h3 className="blue-text text-center">APRIL</h3>
                        <April />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">MAY</h3>
                        <May />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">JUNE</h3>
                        <June />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <h3 className="blue-text text-center">JULY</h3>
                        <July />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">AUGUST</h3>
                        <August />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">SEPTEMBER</h3>
                        <September />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <h3 className="blue-text text-center">OCTOBER</h3>
                        <October />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">NOVEMBER</h3>
                        <November />
                    </div>
                    <div className="col-4">
                        <h3 className="blue-text text-center">DECEMBER</h3>
                        <December />
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Calendar2024;