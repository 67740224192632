import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function OrerePointRegionalPark() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default OrerePointRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Orere Point Regional Park";
export const shortParagraph = "[Permanently Closed]";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMUuChgml_Epo8_szFrw5xxDV6jRP8Jm7UPUePg=s1360-w1360-h1020";
export const photocredits = "Rou Haarhoff";
export const url = "orere-point-regional-park";
export const location = "https://www.google.com/maps/place/Orere+Point+Regional+Park/@-36.9554519,175.2220416,16z/data=!4m10!1m2!2m1!1sOrere+Point+Regional+Park!3m6!1s0x6d72bc32aab21029:0xf00ef62249cf140!8m2!3d-36.9554519!4d175.2315688!15sChlPcmVyZSBQb2ludCBSZWdpb25hbCBQYXJrkgEMY291bnRyeV9wYXJr4AEA!16s%2Fg%2F11p5djk0q8?entry=ttu";
export const address = "Ōrere Point 2585";
export const website = "";
export const cordinates = [-36.9550146529521, 175.2314829689413];