import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function AmburyRegionalPark() {
    return (
        <section className="amburyregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default AmburyRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Ambury Regional Park";
export const shortParagraph = "Ambury Regional Park, situated just 15 kilometers south of Auckland city on the shores of Manukau, is a haven for nature and animal enthusiasts. This picturesque park offers a diverse range of attractions and activities";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipPqvVeHpYwMy61KFUMKszg7aQpMpKDLjixgOVb0=s1360-w1360-h1020";
export const photocredits = "Dylan Wong";
export const url = "ambury-regional-park";
export const location = "https://goo.gl/maps/eHaq67nb7iJeRMTe9";
export const address = "43 Ambury Road, Māngere Bridge, Auckland 2022";
export const website = "";
export const cordinates = [-36.94769945146392, 174.76654088573363];