import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function ScandrettRegionalPark() {
    return (
        <section className="scandrettregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default ScandrettRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Scandrett Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPsXkjXDrmG58HCsM0ApN4TVMde6prUQw2k17qN=s1360-w1360-h1020";
export const photocredits = "Jonathan Boucher";
export const url = "scandrett-regional-park";
export const location = "https://www.google.com/maps/place/Scandrett+Regional+Park/@-36.4439733,174.7686641,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0cd77d56658b83:0x76b2ed974b3cc293!8m2!3d-36.4439776!4d174.771239!16s%2Fm%2F0wzshjb?entry=ttu";
export const address = "114 Scandrett Road, Mahurangi East 0982";
export const website = "";
export const cordinates = [-36.44377043564925, 174.7712175384546];