import React from "react";

function AffliateDisclosure() {
    return (
        <p className="fst-italic">
            <strong>* Affiliate Disclaimer</strong>: This page may contain affiliate links.
            This means that if you make a purchase through these links, I may earn a commission at no extra cost to you.
            Your support goes a long way in helping us continue our journey at asilentexplorer!
        </p>
    );
}

export default AffliateDisclosure;