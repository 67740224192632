import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function GlenfernSanctuary() {
    return (
        <section className="glenfernregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default GlenfernSanctuary;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Glenfern Sanctuary Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMFsdpRPielsWTHAle5-Odn01cU-c3I0D8YXbUs=s1360-w1360-h1020";
export const photocredits = "Pete Parnham";
export const url = "glenfern-sanctuary";
export const location = "https://www.google.com/maps/dir//glenfern+sanctuary+regional+park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d736ca5fbaebf47:0x780c4ad8a3a257ad?sa=X&ved=2ahUKEwis8umh-92CAxXMcmwGHWq8D_4Q9Rd6BAhOEAA";
export const address = "Glenfern Road, Great Barrier Island / Aotea 0963";
export const website = "";
export const cordinates = [-36.16253541116446, 175.35822472494644];