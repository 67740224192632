import React, { useEffect } from "react";
import AppBackground from "../../assets/images/pexels-jonathan-robles-2388936.jpg";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";
import AppWidget from "../travel-guides-inc/AppWidget";
import AffliateDisclosure from "../AffliateDisclosure";


function NzApps() {
    useEffect(() => {
        document.title = 'Must-Have Apps for Exploring New Zealand';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={AppBackground}
                url="https://www.pexels.com/photo/space-grey-apple-iphone-on-notebook-page-2388936/"
                photocredits="Jonathan Robles" />
            <div className="container blog-text">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a></li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a></li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Travel Apps</li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        {/* <AffliateDisclosure /> */}
                        <p className="mt-3 blog-para">
                            Here's your guide to the must-have apps for exploring New Zealand:
                        </p>
                        <h2>AT Mobile</h2>
                        <img src="https://at.govt.nz/media/1990796/at-mobile-template.png?mode=crop&width=1535&heightratio=0.5625&format=jpg"
                            className="mt-2 w-100"
                            alt="" />
                        {/* <p className="mt-3">
                            "AT Mobile" is a versatile mobile application that simplifies public transportation in New Zealand. 
                            With this app, you can effortlessly access bus, ferry, and train timetables, plan your routes, and 
                            stay updated with real-time information to avoid missing your ride. It also offers fare details, 
                            allows you to set favorites, receive notifications, and is designed with accessibility in mind, 
                            catering to the needs of all users.
                            </p> */}
                        <ul className="mt-3">
                            <li>
                                Access timetables for buses, ferries and trains.
                            </li>
                            <li>
                                Plan your public transportation routes.
                            </li>
                            <li>
                                Never miss your ride.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/ea/15/18/ea15186f-bd23-cddf-dd05-1fc54f00a6f8/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/246x0w.webp"
                            title="AT Mobile" company="Auckland Transport"
                            android='https://play.google.com/store/apps/details?id=nz.govt.at.atmobile&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                            ios=""
                        />
                        <hr />
                        <h2>CamperMate - New Zealand Travel App</h2>
                        <div className="d-flex flex-lg-row flex-column">
                        <img src="https://webdata.campermate.com/assets/images/CM-Hero.webp"
                            className="mt-2 p-1" style={{ maxHeight: "400px" }}
                            alt="" />
                        <img src="https://webdata.campermate.com/assets/images/app-preview.png"
                            className="mt-2 p-1" style={{ maxHeight: "400px" }}
                            alt="" />
                        </div>
                        <ul className="mt-3">
                            <li>
                                Discover campsites, public toilets, and more.
                            </li>
                            <li>
                                Real-time location-based information.
                            </li>
                            <li>
                                Plan your road trip with ease.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://play-lh.googleusercontent.com/2y4SuhNxxg-4QVk5p52_nbK3ka1Meqo8_SvKOjZHtrKohsxbJ5gnV0XzpeyrdnjGKL2r=w240-h480-rw"
                            title="CamperMate Australia & NZ" company="GeoZone"
                            android="https://play.google.com/store/apps/details?id=nz.co.campermate&pcampaignid=web_share"
                            ios="https://apps.apple.com/au/app/campermate-australia-nz/id465040067"
                        />
                        <hr />
                        <h2>Lime - Electric Scooter Options</h2>
                        {/* Flamingo, or Onzo */}
                        <img src="https://img.li.me/content/uploads/HERO_SCOOTER.png?auto=compress&crop=focalpoint&fit=crop&fp-x=0.5&fp-y=0.5&h=1000&q=80&w=2058&s=e917b6891524c5119efdf126088ce92f"
                            className="mt-2 w-100"
                            alt="" />
                        <ul className="mt-3">
                            <li>
                                Zip around the city with eco-friendly electric scooters.
                            </li>
                            <li>
                                Find and unlock nearby scooters with the app.
                            </li>
                            <li>
                                Enjoy a fun and convenient way to explore urban areas.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/76/2d/99/762d99ef-3f8e-4669-82f7-d34b60257514/AppIcon-0-1x_U007emarketing-0-5-0-85-220.png/246x0w.webp"
                            title="Lime - #RideGreen" company="Neutron Holdings. Inc"
                            android="https://play.google.com/store/apps/details?id=com.limebike&pcampaignid=web_share"
                            ios="https://apps.apple.com/us/app/lime-ridegreen/id1199780189"
                        />
                        <hr />
                        <h2>Gaspy - Fuel Price Comparison</h2>
                        {/* <img src="https://www.gaspy.nz/assets/gaspy_phones_short.png"
                            className="mt-2 w-100"
                            alt="" /> */}
                        <ul className="mt-3">
                            <li>
                                Get the best fuel prices in your area.
                            </li>
                            <li>
                                Find the nearest and cheapest petrol stations.
                            </li>
                            <li>
                                Save money on your road trips.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/e5/7c/27/e57c27b7-1460-a727-ad45-53184eb819c2/AppIcon-0-0-1x_U007emarketing-0-7-0-0-85-220.png/246x0w.webp"
                            title="Gaspy - Fuel Prices" company="Hwem"
                            android="https://play.google.com/store/apps/details?id=nz.hwem.gaspy&pcampaignid=web_share"
                            ios="https://apps.apple.com/us/app/gaspy/id1157169797"
                        />
                        <hr />
                        {/* <h2>
                            Footprint - Track Your Adventures
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Record your travel experiences and photos.
                            </li>
                            <li>
                                Create a digital travel diary to share with friends.
                            </li>
                            <li>
                                Keep your memories alive with this travel journal.
                            </li>
                        </ul>
                        <hr /> */}
                        <h2>
                            Groupon or GrabOne - Deals and Discounts
                        </h2>
                        <img src="https://mediacdn.grabone.co.nz/asset/VQZn9q6ojK"
                            className="mt-2 w-100"
                            alt="" />
                        <ul className="mt-3">
                            <li>
                                Access exclusive deals on dining, activ ities, and more.
                            </li>
                            <li>
                                Save on your adventures across New Zealand.
                            </li>
                            <li>
                                Discover new experiences at a discount.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://play-lh.googleusercontent.com/BazyRFLG5Jx_gzvD51k_5GCphlcKz_W7Wx1p8VB8kiIxTBc_WrtgQ2m6HI6Xq7Rc4Ago=w240-h480-rw"
                            title="Groupon – Deals & Coupons" company="Groupon, Inc"
                            android="https://play.google.com/store/apps/details?id=com.groupon&pcampaignid=web_share"
                            ios="https://apps.apple.com/us/app/groupon-local-deals-near-me/id352683833?ls=1"
                        />
                        <AppWidget
                            image="https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/50/02/f4/5002f41b-df5f-d001-7476-d40e6aa26da9/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/246x0w.webp"
                            title="GrabOne" company="GrabOne Limited"
                            android="https://play.google.com/store/apps/details?id=com.grabone.goapprn&pcampaignid=web_share"
                            ios="https://apps.apple.com/nz/app/grabone/id1213651425"
                        />
                        <hr />
                        <h2>
                            Airbnb - Your Home Away from Home
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Find unique accommodations in New Zealand.
                            </li>
                            <li>
                                Stay in cozy homes or connect with local hosts.
                            </li>
                            <li>
                                Experience New Zealand like a true Kiwi.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/e1/99/40/e1994087-46e1-579c-ad53-fc9e1c4878d2/AppIcon-0-0-1x_U007emarketing-0-7-0-0-0-85-220.png/246x0w.webp"
                            title="Airbnb" company="Airbnb"
                            android="https://play.google.com/store/apps/details?id=com.airbnb.android&pcampaignid=web_share"
                            ios="https://apps.apple.com/us/app/airbnb/id401626263"
                        />
                        <hr />
                        <h2>
                            Currency Converter Plus
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Convert currencies quickly and accurately.
                            </li>
                            <li>
                                Perfect for travelers and currency exchange needs.
                            </li>
                            <li>
                                Stay on top of exchange rates.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/6d/9d/0a/6d9d0add-387e-e359-81a5-548134a2decf/AppIcon-0-1x_U007epad-0-10-0-85-220.png/246x0w.webp"
                            title="Currency Converter Plus" company="Digitalchemy, LLC"
                            android="https://play.google.com/store/apps/details?id=com.digitalchemy.currencyconverter&pcampaignid=web_share"
                            ios="https://apps.apple.com/us/app/currency-converter-plus-live/id1317641473"
                        />
                        <hr />
                        <h2>
                            MetService Weather
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Get accurate weather forecasts for your location.
                            </li>
                            <li>
                                Plan outdoor activities with confidence.
                            </li>
                            <li>
                                Monitor weather changes in real-time.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://play-lh.googleusercontent.com/UNBPQbc5SNqlD9G_vFQqUE3AP8mQX9qgMZBMUb8Qj4oSjakmLybwummpzk4QW9DjRQ=w240-h480-rw"
                            title="MetService NZ Weather" company="Meteorological Service of New Zealand"
                            android="https://play.google.com/store/apps/details?id=com.metservice.kryten&pcampaignid=web_share"
                            ios="https://apps.apple.com/nz/app/metservice/id525900888?ls=1"
                        />
                        <hr />
                        <h2>
                            Newshub
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Stay updated with the latest news in New Zealand.
                            </li>
                            <li>
                                Get breaking news, sports, and entertainment updates.
                            </li>
                            <li>
                                Stay informed about local and international events.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://play-lh.googleusercontent.com/X_QQC-JF_tT9TNsnfFnDbRcUhJdmqHCgDf3v2fR3LOEOrSZmTEd-rx8f1LOyEP5R_xs=w240-h480-rw"
                            title="Newshub" company="Discovery New Zealand, Mediaworks NZ Ltd"
                            android="https://play.google.com/store/apps/details?id=nz.co.threenews&pcampaignid=web_share"
                            ios="https://apps.apple.com/nz/app/newshub/id385220032"
                        />
                        <hr />
                        {/* <h2>
                            Kiwi Explore
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Find local attractions, restaurants, and events.
                            </li>
                            <li>
                                Unlock discounts at participating businesses.
                            </li>
                            <li>
                                Support local businesses and explore your surroundings.
                            </li>
                        </ul>
                        <hr /> */}
                        <h2>
                            Uber
                        </h2>
                        <ul className="mt-3">
                            <li>
                                Convenient transportation in New Zealand cities.
                            </li>
                            <li>
                                Request a ride with ease.
                            </li>
                            <li>
                                Track your driver's arrival in real-time.
                            </li>
                        </ul>
                        <AppWidget
                            image="https://play-lh.googleusercontent.com/AQtSF5Sl18yp3mQ2tcbOrBLekb7cyP3kyg5BB1uUuc55zfcnbkCDLHFTBwZfYiu1aDI=w240-h480-rw"
                            title="Uber - Request a ride" company="Uber Technologies, Inc"
                            android="https://play.google.com/store/apps/details?id=com.ubercab&pcampaignid=web_share"
                            ios="https://apps.apple.com/us/app/uber-request-a-ride/id368677368"
                        />
                        <hr />
                        <p>
                            With these essential apps, your New Zealand adventure will be more enjoyable, convenient,
                            and memorable. Whether you're exploring the great outdoors, enjoying city life, or seeking
                            the best deals, these apps will be your ultimate travel companions. Download them now and
                            make the most of your New Zealand journey.
                        </p>
                        <p>
                            Explore New Zealand with confidence and ease, thanks to these fantastic apps!
                        </p>

                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default NzApps;

export const title = "Must-Have Apps for Exploring New Zealand";
export const shortParagraph = "New Zealand offers breathtaking landscapes, vibrant cities, and unique experiences. To make the most of your Kiwi adventure, consider these essential apps. Whether you're a traveler, student, or resident, these apps will enhance your New Zealand experience.";
export const date = "28 October 2023";
export const image = AppBackground;
export const url = "newzealand-travel-apps";