import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function TeArai() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default TeArai;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Te Ārai Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipP67DHcoTo87c0QWeepY6SoiFUMWzdRDa7qdw4C=s1360-w1360-h1020";
export const photocredits = "Elias Harb";
export const url = "te-arai-regional-park";
export const location = "https://www.google.com/maps/place/Te+Arai+Regional+Park/@-36.1600191,174.6447029,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0cc01edd423027:0x611d2a68665a4592!8m2!3d-36.1600235!4d174.6472778!16s%2Fg%2F11hcyynp51?entry=ttu";
export const address = "708 Te Arai Point Road, Te Arai 0975";
export const website = "";
export const cordinates = [-36.15986755332752, 174.64723488076805];