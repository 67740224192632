import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function AshleyRakahuriRegionalPark() {
    return (
        <section className="ashleyrakahuriregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default AshleyRakahuriRegionalPark;

export const tags = ["regionalpark", "canterbury", "south-island"];
export const title = "Ashley Rakahuri Regional Park";
export const shortParagraph = "";
export const date = "14 November 2023";
export const image = "https://scontent.fakl6-1.fna.fbcdn.net/v/t1.6435-9/158442227_4515767898449892_8918777525066830362_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=9b3078&_nc_ohc=MNNFyCPSCEoAX_vnsjJ&_nc_ht=scontent.fakl6-1.fna&oh=00_AfBA5yVRkhSXeRpGpy6hqZK4Vsmnv2l5SmWmXoA6n-1SKw&oe=657A679F";
export const photocredits="Environment Canterbury Regional Parks";
export const url = "ashley-rakahuri-regional-park";
export const location = "";
export const address = "";
export const website = "https://www.facebook.com/Ecan.Parks";
export const cordinates = [-43.27977440610305, 172.60822796054234];