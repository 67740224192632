import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faFilter, faSort } from "@fortawesome/free-solid-svg-icons";
import Banner from "../assets/images/RegionalPark.png";

function RegionalParks() {
    const navigate = useNavigate();
    const navigateToUrl = (url) => {
        navigate(url);
    };

    const importAll = (r) => {
        return r.keys().map(r);
    };

    const regionalParks = importAll(require.context('./regionalparks', false, /\.js$/));

    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedIslands, setSelectedIslands] = useState([]);
    const [currentLocation, setCurrentLocation] = useState([]);
    const [regionalParksWithCoords, setRegionalParksWithCoords] = useState([]);
    const [sortBy, setSortBy] = useState("Default");
    const [regionalParksCount, setRegionalParksCount] = useState(0);

    const handleCheckboxChange = (value, category) => {
        if (category === "Regions") {
            setSelectedRegions((prevSelected) =>
                prevSelected.includes(value)
                    ? prevSelected.filter((region) => region !== value)
                    : [...prevSelected, value]
            );
        } else if (category === "Islands") {
            setSelectedIslands((prevSelected) =>
                prevSelected.includes(value)
                    ? prevSelected.filter((island) => island !== value)
                    : [...prevSelected, value]
            );
        }
    };

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCurrentLocation([latitude, longitude]);
    }

    function error() {
        console.log("Unable to retrieve your location");
    }

    useEffect(() => {
        if (sortBy === "Default") {
            setRegionalParksWithCoords(regionalParks);
        } else if (sortBy === "Distance" && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }
    }, [sortBy]);

    useEffect(() => {
        if (sortBy === "Distance" && currentLocation.length === 2) {
            const regionalParksWithCoords = regionalParks.map((item) => ({
                ...item,
                latitude: item.cordinates[0],
                longitude: item.cordinates[1],
            }));

            regionalParksWithCoords.sort((a, b) => {
                const distanceA = haversine(currentLocation[0], currentLocation[1], a.latitude, a.longitude);
                const distanceB = haversine(currentLocation[0], currentLocation[1], b.latitude, b.longitude);
                return distanceA - distanceB;
            });

            setRegionalParksWithCoords(regionalParksWithCoords);
        }
    }, [currentLocation]);

    const handleLocationChange = (value) => {
        setSortBy((prevSortBy) => (prevSortBy === value ? "Default" : value));
    };

    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    // Import the Haversine formula function
    const haversine = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Earth's radius in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    const regionalParksInfo = regionalParksWithCoords.map((page, index) => {
        const { title, shortParagraph, image, url, location, address, tags, cordinates } = page;

        const hasNoFilterCondition = selectedRegions.length === 0 && selectedIslands.length === 0;
        const hasSelectedRegion = selectedRegions.length !== 0 && selectedRegions.some(region => page.tags.includes(region));
        const hasSelectedIsland = selectedIslands.length !== 0 && selectedIslands.some(island => page.tags.includes(island));

        if (hasNoFilterCondition || hasSelectedRegion || hasSelectedIsland) {
            return (
                <div key={index} className="m-1">
                    <div className="card mb-3 blog-card">
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={image} className="img-fluid rounded-start h-100" alt="New Zealand Regional Parks" style={{ pointerEvents: "none" }} />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title fs-2" style={{ color: "#087EA6" }}>{title}</h5>
                                    {
                                        shortParagraph ? (
                                            <p className="card-text py-2 disable-text-selection">{shortParagraph}</p>
                                        ) : (
                                            null
                                        )
                                    }
                                    {
                                        location ? (
                                            <a href={location} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                                <p className="card-text me-2"><small className="text-body-secondary">
                                                    <FontAwesomeIcon icon={faLocationDot} className="me-2" />{address}</small></p>
                                            </a>
                                        ) : (
                                            null
                                        )
                                    }
                                    <button type="button" className="btn btn-primary my-4" onClick={() => navigateToUrl(url)}>Read more</button>

                                    <div className="mt-4">
                                        {
                                            tags.map((tag, tagIndex) => (
                                                <button key={tagIndex} type="button" className="btn btn-outline-primary m-2">{tag}</button>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    });

    useEffect(() => {
        const filteredParks = regionalParksInfo.filter(Boolean); // Filter out undefined elements
        setRegionalParksCount(filteredParks.length);
    }, [regionalParksInfo]);

    return (
        <section className="nztravelguide">
            <div className="banner"
                style={{
                    backgroundImage: `url(${Banner})`
                }}></div>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <nav className="pt-3" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/" className="blue-text">Home</a></li>
                                <li className="breadcrumb-item"><a href="/" className="blue-text">New Zealand</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Regional Parks</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex justify-content-lg-end align-items-center">
                        <div>
                            <p className="pt-3"><FontAwesomeIcon icon={faFilter} /> Filter by:</p>
                        </div>
                        <div className="btn-group">
                            <button type="button" className="btn dropdown-toggle w-100" data-bs-toggle="dropdown" aria-expanded="false">
                                Regions
                            </button>
                            <ul className="dropdown-menu dropdown-menu-lg-end dropdown-menu-center">
                                <li className="p-2 ms-2"><label><input type="checkbox" value="north-island" onChange={() => handleCheckboxChange("north-island", "Islands")} /> North Island</label></li>
                                <li className="p-2 ms-4"><label><input type="checkbox" value="auckland" onChange={() => handleCheckboxChange("auckland", "Regions")} /> Auckland</label></li>
                                <li className="p-2 ms-4"><label><input type="checkbox" value="bay-of-plenty" onChange={() => handleCheckboxChange("bay-of-plenty", "Regions")} /> Bay of Plenty</label></li>
                                <li className="p-2 ms-4"><label><input type="checkbox" value="wellington" onChange={() => handleCheckboxChange("wellington", "Regions")} /> Wellington</label></li>

                                <li className="p-2 ms-2"><label><input type="checkbox" value="south-island" onChange={() => handleCheckboxChange("south-island", "Islands")} /> South Island</label></li>
                                <li className="p-2 ms-4"><label><input type="checkbox" value="canterbury" onChange={() => handleCheckboxChange("canterbury", "Regions")} /> Canterbury</label></li>

                                <li className="p-2 ms-2"><label><input type="checkbox" value="other-islands" onChange={() => handleCheckboxChange("other-islands", "Islands")} /> Other Islands</label></li>
                            </ul>
                        </div>
                        <div>
                            <p className="pt-3">{regionalParksCount} results found</p>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 d-flex justify-content-lg-end align-items-center">
                        <div>
                            <p className="pt-3"><FontAwesomeIcon icon={faSort} /> Sort by:</p>
                        </div>
                        <div className="btn-group">
                            <button type="button" className="btn dropdown-toggle w-100" data-bs-toggle="dropdown" aria-expanded="false">
                                {sortBy}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-lg-end dropdown-menu-center">
                                <li className="p-2 ms-2">
                                    <label>
                                        <input type="radio" value="Default" checked={sortBy === "Default"} onChange={() => handleLocationChange("Default")} /> Default
                                    </label>
                                </li>
                                <li className="p-2 ms-2">
                                    <label>
                                        <input type="radio" value="Distance" checked={sortBy === "Distance"} onChange={() => handleLocationChange("Distance")} /> Distance
                                    </label>
                                </li>
                                {/* <li className="p-2 ms-2"><label><input type="checkbox" value="nearest" onChange={() => handleLocationChange("most rated")} /> Most Rated</label></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                {regionalParksInfo}
            </div>
        </section >
    );
}

export default RegionalParks;