import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './main-pages/Home';
import Navbar from './common-inc/Navbar';
import Footer from './common-inc/Footer';
import WageCalculator from './services/WageCalculator';
import NzSpring2023 from './pages/travel-guides/NzSpring2023';
import TheTeam from './company/TheTeam';
import MissionVision from './company/MissionVision';
import PageNotFound from './common-inc/PageNotFound';
import NzApps from './pages/travel-guides/NzApps';
import NzTravelGuide from './pages/NzTravelGuide';
import IndianLicenceConversion from './pages/travel-guides/IndianLicenceConversion';
import FullLicenceTestRoutes from './pages/travel-guides/FullLicenceTestRoutes';
import Auckland from './destinationNZ/places/Auckland';
import AucklandInfo from './destinationNZ/cityInfo/AucklandInfo';
import NorthIsland from './islandsNZ/NorthIsland';
import AucklandAttractions from './destinationNZ/see_n_do/AucklandAttractions';
import AucklandEvents from './destinationNZ/events/AucklandEvents';
import RegionalParks from './destinationNZ/RegionalParks';
import AmburyRegionalPark from './destinationNZ/regionalparks/Ambury';
import HunuaRangesRegionalPark from './destinationNZ/regionalparks/Hunua';
import AshleyRakahuriRegionalPark from './destinationNZ/regionalparks/AshleyRakahuri';
import LakeTekapoRegionalPark from './destinationNZ/regionalparks/LakeTekapoRegionalPark';
import NorthernPegasusBay from './destinationNZ/regionalparks/NorthernPegasusBay';
import ShakespearRegionalPark from './destinationNZ/regionalparks/ShakespearRegionalPark';
import WaimakaririRiverRegionalPark from './destinationNZ/regionalparks/WaimakaririRiverRegionalPark';
import WaitakereRangesRegionalPark from './destinationNZ/regionalparks/WaitakereRanges';
import WaitawaRegionalPark from './destinationNZ/regionalparks/Waitawa';
import BasicMaoriWords from './pages/travel-guides/BasicMaoriWords';
import OnekawaTeMawhaiRegionalPark from './destinationNZ/regionalparks/OnekawaTeMawhai';
import PapamoaHillsRegionalPark from './destinationNZ/regionalparks/PapamoaHills';
import SouthIsland from './islandsNZ/SouthIsland';
import OtherIslands from './islandsNZ/OtherIslands';
import NzSeasons from './pages/travel-guides/NzSeasons';
import SummerInNz from './pages/travel-guides/SummerInNz';
import WinterInNz from './pages/travel-guides/WinterInNz';
import EmergencyServices from './pages/travel-guides/EmergencyServices';
import SpringInNz from './pages/travel-guides/SpringInNz';
import AutumnInNz from './pages/travel-guides/AutumnInNz';
import AtiuCreek from './destinationNZ/regionalparks/AtiuCreek';
import AucklandBotanicGardens from './destinationNZ/regionalparks/AucklandBotanicGardens';
import AwhituRegionalPark from './destinationNZ/regionalparks/AwhituRegionalPark';
import GlenfernSanctuary from './destinationNZ/regionalparks/GlenfernSanctuary';
import DuderRegionalPark from './destinationNZ/regionalparks/DuderRegionalPark';
import LongBayRegionalPark from './destinationNZ/regionalparks/LongBayRegionalPark';
import MotukoreaBrownsIsland from './destinationNZ/regionalparks/MotukoreaBrownsIsland';
import MuriwaiRegionalPark from './destinationNZ/regionalparks/MuriwaiRegionalPark';
import Mutukaroa from './destinationNZ/regionalparks/Mutukaroa';
import OmanaRegionalPark from './destinationNZ/regionalparks/OmanaRegionalPark';
import OrerePointRegionalPark from './destinationNZ/regionalparks/OrerePointRegionalPark';
import PakiriRegionalPark from './destinationNZ/regionalparks/PakiriRegionalPark';
import ScandrettRegionalPark from './destinationNZ/regionalparks/ScandrettRegionalPark';
import Tapapakanga from './destinationNZ/regionalparks/Tapapakanga';
import Tawharanui from './destinationNZ/regionalparks/Tawharanui';
import Tawhitokino from './destinationNZ/regionalparks/Tawhitokino';
import TeArai from './destinationNZ/regionalparks/TeArai';
import TeRauPuriri from './destinationNZ/regionalparks/TeRauPuriri';
import WaharauRegionalPark from './destinationNZ/regionalparks/WaharauRegionalPark';
import Wenderholm from './destinationNZ/regionalparks/Wenderholm';
import Whakanewha from './destinationNZ/regionalparks/Whakanewha';
import Whakatiwai from './destinationNZ/regionalparks/Whakatiwai';
import ScrollToTop from './common-inc/ScrollToTop';
import Calendar2024 from './calendar2024/Calendar2024';
import Calendar2024_1 from './calendar2024/Calendar2024_1';
import Calendar2024_2 from './calendar2024/Calendar2024_2';
import Calendar2024_3 from './calendar2024/Calendar2024_3';
import Calendar2024_4 from './calendar2024/Calendar2024_4';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/nz/destination" element={<Destination />} /> */}
          <Route path="/destinations/newzealand/services/wagecalculator" element={<WageCalculator />} />
          <Route path="/destinations/newzealand/travel-guide" element={<NzTravelGuide />} />
          <Route path="/destinations/newzealand/travel-guide/newzealand-cherry-blossom-2023" element={<NzSpring2023 />} />
          <Route path="/destinations/newzealand/travel-guide/newzealand-travel-apps" element={<NzApps />} />
          <Route path="/destinations/newzealand/travel-guide/indian-licence-to-new-zealand-licence-conversion-guide" element={<IndianLicenceConversion />} />
          <Route path="/destinations/newzealand/travel-guide/new-zealand-full-licence-test-routes" element={<FullLicenceTestRoutes />} />
          <Route path="/destinations/newzealand/travel-guide/basic-maori-words" element={<BasicMaoriWords />} />
          <Route path="/destinations/newzealand/travel-guide/newzealand-seasons-guide" element={<NzSeasons />} />
          <Route path="/destinations/newzealand/travel-guide/summer-in-newzealand" element={<SummerInNz />} />
          <Route path="/destinations/newzealand/travel-guide/winter-in-newzealand" element={<WinterInNz />} />
          <Route path="/destinations/newzealand/travel-guide/spring-in-newzealand" element={<SpringInNz />} />
          <Route path="/destinations/newzealand/travel-guide/autumn-in-newzealand" element={<AutumnInNz />} />
          <Route path="/destinations/newzealand/travel-guide/emergency-services" element={<EmergencyServices />} />

          {/* <Route path="/destinations/newzealand/north-island/auckland" element={<Auckland />} />
          <Route path="/destinations/newzealand/north-island/auckland/city-info" element={<AucklandInfo />} /> */}
          <Route path="/destinations/newzealand/north-island" element={<NorthIsland />} />
          <Route path="/destinations/newzealand/south-island" element={<SouthIsland />} />
          <Route path="/destinations/newzealand/other-islands" element={<OtherIslands />} />

          <Route
            path="/destinations/newzealand/north-island/auckland"
            element={
              <Auckland>
                <Route index element={<Auckland />} />
                <Route path="city-info" element={<AucklandInfo />} />
                <Route path="see-n-do" element={<AucklandAttractions />} />
                <Route path="events" element={<AucklandEvents />} />
              </Auckland>
            }
          />

          <Route path="/destinations/newzealand/regional-parks" element={<RegionalParks />} />
          <Route path="/destinations/newzealand/regional-parks/ambury-regional-park" element={<AmburyRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/ashley-rakahuri-regional-park" element={<AshleyRakahuriRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/hunua-ranges" element={<HunuaRangesRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/lake-tekapo-regional-park" element={<LakeTekapoRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/northern-pegasus-bay" element={<NorthernPegasusBay />} />
          <Route path="/destinations/newzealand/regional-parks/shakespear-regional-park" element={<ShakespearRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/waimakariri-river-regional-park" element={<WaimakaririRiverRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/waitakere-ranges" element={<WaitakereRangesRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/waitawa-regional-park" element={<WaitawaRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/onekawa-te-mawhai" element={<OnekawaTeMawhaiRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/papamoa-hills" element={<PapamoaHillsRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/atiu-creek-regional-park" element={<AtiuCreek />} />
          <Route path="/destinations/newzealand/regional-parks/auckland-botanic-gardens" element={<AucklandBotanicGardens />} />
          <Route path="/destinations/newzealand/regional-parks/awhitu-regional-park" element={<AwhituRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/glenfern-sanctuary" element={<GlenfernSanctuary />} />
          <Route path="/destinations/newzealand/regional-parks/duder-regional-park" element={<DuderRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/long-bay-regional-park" element={<LongBayRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/motukorea-browns-island" element={<MotukoreaBrownsIsland />} />
          <Route path="/destinations/newzealand/regional-parks/muriwai-regional-park" element={<MuriwaiRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/mutukaroa-regional-park" element={<Mutukaroa />} />
          <Route path="/destinations/newzealand/regional-parks/omana-regional-park" element={<OmanaRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/orere-point-regional-park" element={<OrerePointRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/pakiri-regional-park" element={<PakiriRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/scandrett-regional-park" element={<ScandrettRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/tapapakanga-regional-park" element={<Tapapakanga />} />
          <Route path="/destinations/newzealand/regional-parks/tawharanui-regional-park" element={<Tawharanui />} />
          <Route path="/destinations/newzealand/regional-parks/tawhitokino-regional-park" element={<Tawhitokino />} />
          <Route path="/destinations/newzealand/regional-parks/te-arai-regional-park" element={<TeArai />} />
          <Route path="/destinations/newzealand/regional-parks/te-rau-puriri-regional-park" element={<TeRauPuriri />} />
          <Route path="/destinations/newzealand/regional-parks/waharau-regional-park" element={<WaharauRegionalPark />} />
          <Route path="/destinations/newzealand/regional-parks/wenderholm-regional-park" element={<Wenderholm />} />
          <Route path="/destinations/newzealand/regional-parks/whakanewha-regional-park" element={<Whakanewha />} />
          <Route path="/destinations/newzealand/regional-parks/whakatiwai-regional-park" element={<Whakatiwai />} />

          <Route path="/destinations/newzealand/shop/calendar2024" element={<Calendar2024 />} />
          <Route path="/destinations/newzealand/shop/calendar2024_1" element={<Calendar2024_1 />} />
          <Route path="/destinations/newzealand/shop/calendar2024_2" element={<Calendar2024_2 />} />
          <Route path="/destinations/newzealand/shop/calendar2024_3" element={<Calendar2024_3 />} />
          <Route path="/destinations/newzealand/shop/calendar2024_Img" element={<Calendar2024_4 />} />

          <Route path="/theteam" element={<TheTeam />} />
          <Route path="/values" element={<MissionVision />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ScrollToTop />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
