import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

function SocialWidgets() {
    const youtubeChannelId = "UCykdbDtxypns1cxBnt-tuJQ";
    const [subscribers, setSubscribers] = useState(52);
    const instaFollowers = 435;
    const facebookFans = 4;
    const tiktokFans = 85;
    const API_KEY = "AIzaSyDmTojFDOFUpjlr6e-lnKT5sHBn5UD2bOI";

    useEffect(() => {
        fetch(
            `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${youtubeChannelId}&key=${API_KEY}`
        )
            .then((data) => data.json())
            .then((result) => {
                setSubscribers(result.items[0].statistics.subscriberCount);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setSubscribers(52);
            });
    }, []);

    const counters = document.querySelectorAll(".counter");

    counters.forEach((counter) => {
        counter.innerText = "0";
        const updateCounter = () => {
            const target = +counter.getAttribute("data-target");
            const count = +counter.innerText;
            const increment = target / 200;
            if (count < target) {
                counter.innerText = `${Math.ceil(count + increment)}`;
                setTimeout(updateCounter, 1);
            } else counter.innerText = target;
        };
        updateCounter();
    });

    return (
        <section className="social-widgets">
            <div className="container p-5">
                <h1 className="blue-text text-uppercase text-center">We're Growing!</h1>
                <div className="row g-2 mt-5">
                    <div className="col-lg-3 col-6" data-aos="zoom-in-up">
                        <a href="https://www.facebook.com/asilentexplorer/"
                            className="fb-widget rounded text-center p-3 d-block"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "#fff" }}>
                            <FontAwesomeIcon icon={faFacebook} className="mb-2" />
                            <div className="counter" data-target={facebookFans}></div>
                            <span className="small">Fans</span>
                        </a>
                    </div>

                    <div className="col-lg-3 col-6" data-aos="zoom-in-up">
                        <a href="https://www.instagram.com/asilentexplorer/"
                            className="insta-widget rounded text-center p-3 d-block"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "#fff" }}>
                            <FontAwesomeIcon icon={faInstagram} className="mb-2" />
                            <div className="counter" data-target={instaFollowers}></div>
                            <span className="small">Followers</span>
                        </a>
                    </div>
                    <div className="col-lg-3 col-6" data-aos="zoom-in-up">
                        <a href="https://youtube.com/@asilentexplorer"
                            className="yt-widget rounded text-center p-3 d-block"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "#fff" }}>
                            <FontAwesomeIcon icon={faYoutube} className="mb-2" />
                            <div className="counter" data-target={subscribers}></div>
                            <span className="small">Subs</span>
                        </a>
                    </div>
                    <div className="col-lg-3 col-6" data-aos="zoom-in-up">
                        <a href="https://www.tiktok.com/@asilentexplorer"
                            className="tiktok-widget rounded text-center p-3 d-block"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "#fff" }}>
                            <FontAwesomeIcon icon={faTiktok} className="mb-2" />
                            <div className="counter" data-target={tiktokFans}></div>
                            <span className="small">Fans</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SocialWidgets;