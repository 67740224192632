import React from "react";
import AucklandCity from "../../assets/images/city-info/pexels-liam-spicer-5342978.jpg";


function AucklandInfo(props) {
    return (
        <section className="city-info">
            <div className="row my-2 d-flex g-5">
                <div className="col-md-6 justify-content-center">
                    <div className="p-2">
                        <img src={AucklandCity}
                            className="w-100" 
                            style={{maxHeight:"300px"}}
                            alt="Photo by Liam Spicer: https://www.pexels.com/photo/the-pink-lightpath-of-auckland-new-zealand-5342978/" />
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <p className="p-2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </div>
            </div>

        </section>
    )
}

export default AucklandInfo;