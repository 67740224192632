import React, { useEffect } from "react";
import AppBackground from "../../assets/images/111.png";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function EmergencyServices() {
    useEffect(() => {
        document.title = 'Emergency Services';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="asilentexplorer" />
            <div className="container blog-text">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Emergency Services
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 blog-para">

                        </p>
                        <div className="alert alert-danger text-center" role="alert">
                            111 is the emergency number for Police, Fire and Ambulance.
                        </div>

                        <div className="d-flex">
                            <div className="alert alert-success text-center col me-1" role="alert">
                                105 is for non-emergency calls to police.
                            </div>

                            <div className="alert alert-success text-center col" role="alert">
                                *555 is for traffic calls
                            </div>
                        </div>

                        <h3 className="blue-text">Free Call Services</h3>
                        <table className="table table-light table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"></th>
                                    <th scope="col">Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Report crime anonymously</td>
                                    <td>0800 555 111</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Healthline</td>
                                    <td>0800 611 116</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Report a cyber security incident and request support</td>
                                    <td>(04) 498 7654<br/>incidents@ncsc.govt.nz</td>
                                </tr>
                            </tbody>
                        </table>

                        <h3 className="blue-text">Immigration Services</h3>
                        <table className="table table-light table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Where</th>
                                    <th scope="col">Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Contact Centre (Toll-free from NZ landlines only)</td>
                                    <td>0508 558 855</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Auckland</td>
                                    <td>+64 9 914 4100</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Wellington</td>
                                    <td>+64 4 910 9915</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Rest of the world</td>
                                    <td>+64 9 914 4100</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            For more information on contacting Immigration New Zealand, 
                            visit their official <a href="https://www.immigration.govt.nz/about-us/contact" target="_blank" rel="noopener noreferrer">website</a>.
                        </p>

                        <h3 className="blue-text">Other Services</h3>
                        <table className="table table-light table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"></th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Police National Headquarters</td>
                                    <td>180 Molesworth St, Wellington, PO Box 3017</td>
                                    <td>Ph:(04) 474 9499 <br />
                                        Fax:(04) 498 7400</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default EmergencyServices;

export const title = "Emergency Services in New Zealand";
export const shortParagraph = "";
export const date = "22 November 2023";
export const image = AppBackground;
export const url = "emergency-services";