import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function HunuaRangesRegionalPark() {
    return (
        <section className="amburyregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default HunuaRangesRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Hunua Ranges Regional Park";
export const shortParagraph = "Hunua Ranges Regional Park, located in Auckland, New Zealand, is a breathtaking natural reserve known for its stunning landscapes and diverse outdoor activities. Nestled in the Waikato region, this expansive park offers visitors a rich tapestry of experiences.";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipO_EMLkG8SMWiHYrxYpKVUT46rjnHx7m341TBN6=s1360-w1360-h1020";
export const photocredits="Nellie Vink";
export const url = "hunua-ranges";
export const location = "https://www.google.com/maps/dir//hunua+ranges+regional+park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d72a37d003ee987:0xae30667c5d0b70b8?sa=X&ved=2ahUKEwiqy_LOrceCAxVvbvUHHTaIB0AQ9Rd6BAhbEAA";
export const address = "203 Falls Road, Hunua 2583";
export const website = "";
export const cordinates = [-37.06675976404223, 175.08975293095048];