import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Tawhitokino() {
    return (
        <section className="tawhitokinoregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Tawhitokino;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Tawhitokino Regional Park";
export const shortParagraph = "You can only access Tawhitokino beach from Waiti Bay at low tide, or the hour either side as the tide changes. Check the tides and plan your trip carefully. The walk through the low tide section will take up to 30 minutes.";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipNtUGQjNoHN8PM9PNcUUeZ78Ih0Bjgbm1heVyeR=s1360-w1360-h1020";
export const photocredits = "Ilia Nedoluzhko";
export const url = "tawhitokino-regional-park";
export const location = "https://www.google.com/maps/dir/-36.9159618,174.8046436/-36.944,175.210131/@-36.9609439,174.6665227,10z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0?entry=ttu";
export const address = "265 Kawakawa Bay Coast Road, Orere Point";
export const website = "";
export const cordinates = [-36.92307687148369, 175.20759933448142];