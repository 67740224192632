import React from "react";
import ChooseYourDestination from "../home-inc/ChooseYourDestination";
import Subscribe from "../home-inc/Subscribe";
import PopularTours from "../home-inc/PopularTours";
import SpringRetreats from "../home-inc/SpringRetreats";
import Adventures from "../home-inc/Adventures";
import ShopBanner from "../home-inc/ShopBanner";
import SocialWidgets from "../home-inc/Social-Widgets";
import SeasonBanner from "../home-inc/SeasonBanner";

function Home() {
    return (
        <React.Fragment>
            <section className="hero">
                    <div className="card-dark-overlay">
                        <div className="container home-banner">
                            <p className="fs-6 text-uppercase text-center">
                                TRAVEL NEW ZEALAND WITH
                            </p>
                            <h1 className="fw-bold mb-4 fs-1">
                                A Silent Explorer
                            </h1>
                            <p className="fs-4 px-5 mb-5 text-center">
                                New Zealand Uncovered: The Ultimate Guide to Exploring Aotearoa's Stunning Landscapes and Unique Culture
                            </p>
                            {/* <button type="button" className="btn ase-btn">Get Started</button> */}
                        </div>
                </div>
            </section>
            <ChooseYourDestination />
            {/* <SpringRetreats /> */}
            <SeasonBanner/>
            <SocialWidgets />
            {/* <ShopBanner/> */}
            {/* <PopularTours /> */}
            <Adventures />
            {/* <Subscribe /> */}
            <div className="container p-2">
                <hr />
                <p className="fst-italic text-center">
                    asilentexplorer is a participant in the Amazon Services LLC Associates Program,
                    an affiliate advertising program designed to provide a means for us to earn fees
                    by linking to Amazon.com and affiliated sites
                </p>
            </div>
        </React.Fragment>
    );
}

export default Home;