import React from "react";
import Cherry from "../assets/images/pexels-brett-sayles-992734.jpg";

function SeasonBanner() {
    return (
        <section className="seasonbanner mb-5">
            <div className="container pt-5">
                <h1 className="blue-text text-center text-uppercase">The Seasonal Calendar of New Zealand</h1>
                <div className="row">
                    <div className="col-lg-3 p-2">
                        <a href="/destinations/newzealand/travel-guide/summer-in-newzealand" className="text-decoration-none">
                            <div className="card text-center text-white mb-3 h-100" style={{ border: "none", backgroundColor: "#087EA6" }}>
                                <img src="https://lh5.googleusercontent.com/p/AF1QipO59fa5VTHv5w20rP5MX73fj4JcA5NS_blQMaeq=s1360-w1360-h1020"
                                    className="card-img h-100" alt="Mount Taranaki Photo by Rick Shen" />
                                <div className="card-body">
                                    <h5 className="card-title">Summer</h5>
                                    <p className="card-text">Dec, Jan, Feb</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-lg-3 p-2">
                        <a href="/destinations/newzealand/travel-guide/autumn-in-newzealand" className="text-decoration-none">
                            <div className="card text-center text-white mb-3 h-100" style={{ border: "none", backgroundColor: "#087EA6" }}>
                                <img src="https://www.drivingyou.co.nz/uploads/6/8/8/2/68827197/josbuurmans-2137_1_orig.jpg"
                                    className="card-img h-100" alt="Hawke's Bay Photo by Jos Buurmans" />
                                <div className="card-body">
                                    <h5 className="card-title">Autumn</h5>
                                    <p className="card-text"></p>
                                    <p className="card-text">Mar, Apr, May</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-lg-3 p-2">
                        <a href="/destinations/newzealand/travel-guide/winter-in-newzealand" className="text-decoration-none">
                            <div className="card text-center text-white mb-3 h-100" style={{ border: "none", backgroundColor: "#087EA6" }}>
                                <img src="https://lh5.googleusercontent.com/p/AF1QipOAZzHfiHICFSeGlYeG9ycovYwQX3g3RqDehv0U=s1360-w1360-h1020"
                                    className="card-img h-100" alt="Photo by Thomas Quayle" />
                                <div className="card-body">
                                    <h5 className="card-title">Winter</h5>
                                    <p className="card-text"></p>
                                    <p className="card-text">Jun, Jul, Aug</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-lg-3 p-2">
                        <a href="/destinations/newzealand/travel-guide/spring-in-newzealand" className="text-decoration-none">
                            <div className="card text-center text-white mb-3 h-100" style={{ border: "none", backgroundColor: "#087EA6" }}>
                                <img src={Cherry} className="card-img h-100" alt="Cherry blossom" />
                                <div className="card-body">
                                    <h5 className="card-title">Spring</h5>
                                    <p className="card-text"></p>
                                    <p className="card-text">Sep, Oct, Nov</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SeasonBanner;