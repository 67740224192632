import React, { useRef } from 'react';
import January from "./months_2/January";
import February from "./months_2/February";
import March from "./months_2/March";
import April from "./months_2/April";
import May from "./months_2/May";
import June from "./months_2/June";
import July from "./months_2/July";
import August from "./months_2/August";
import September from "./months_2/September";
import November from "./months_2/November";
import December from "./months_2/December";
import October from "./months_2/October";
import { pdfjs } from "react-pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function Calendar2024_3() {
    // Trying Canva
    const calendarRef = useRef(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


    const generateImgPDF = async () => {
        const input = calendarRef.current;
        const pdf = new jsPDF("p", "mm", "a4");
    
        // Use html2canvas to capture the content of the specified element
        const canvas = await html2canvas(input);
    
        // Add the captured image to the PDF
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 10, 10, 190, 277); // Adjust the position and dimensions as needed
    
        // Save the PDF
        pdf.save("calendar2024.pdf");
      };

    return (
        <section className="calendar2024">
            <div className="container mt-5">
                <div className="row">
                    <button className="btn btn-outline-secondary" onClick={generateImgPDF}>Print</button>
                </div>
            </div>

            <div className="container my-5 shadow" id="calendar" style={{ width: '595pt', height: '842pt', margin: 'auto', padding: '5mm', backgroundColor: '#fff' }} ref={calendarRef}>
                <div className="row mb-5" style={{ backgroundColor: "#087EA6" }}>
                    <h1 className="text-center">CALENDAR 2024</h1>
                </div>

                <div className="row">
                    <div className="col-4">
                        <h5 className="blue-text text-center">JANUARY</h5>
                        <January />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">FEBRUARY</h5>
                        <February />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">MARCH</h5>
                        <March />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <h5 className="blue-text text-center">APRIL</h5>
                        <April />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">MAY</h5>
                        <May />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">JUNE</h5>
                        <June />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <h5 className="blue-text text-center">JULY</h5>
                        <July />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">AUGUST</h5>
                        <August />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">SEPTEMBER</h5>
                        <September />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <h5 className="blue-text text-center">OCTOBER</h5>
                        <October />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">NOVEMBER</h5>
                        <November />
                    </div>
                    <div className="col-4">
                        <h5 className="blue-text text-center">DECEMBER</h5>
                        <December />
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Calendar2024_3;