import React, { useEffect } from "react";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function BasicMaoriWords() {
    useEffect(() => {
        document.title = 'Basic Maori Words';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="Roland Christian Richter" />
            <div className="container blog-text">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Maori Language
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3">
                            Here's a concise list to get you started!
                        </p>
                        <h3 className="blue-text">Know the Basics:</h3>

                        <table className="table table-light table-hover">
                            {/* <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead> */}
                            <tbody>
                                <tr>
                                    <td>Kia Ora</td>
                                    <td>hello/thank you/goodbye</td>
                                    <td>depending on context. It's commonly used and a great way to start interactions in New Zealand</td>
                                </tr>
                                <tr>
                                    <td>Tēnā koe</td>
                                    <td>hello</td>
                                    <td>Formal greeting, addressed to one person only</td>
                                </tr>
                                <tr>
                                    <td>Tēnā rawa atu koe </td>
                                    <td>thankyou very much</td>
                                    <td>addressed to one person only</td>
                                </tr>
                                <tr>
                                    <td>Haere Ma /<br/>Nau mai</td>
                                    <td>come here/welcome</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>E noho ra</td>
                                    <td>stay well</td>
                                    <td>Use this phrase to bid farewell</td>
                                </tr>
                                <tr>
                                    <td>Aroha mai</td>
                                    <td>Sorry</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default BasicMaoriWords;

export const title = "Appreciating Maori Culture: Know the Language";
export const shortParagraph = "Maori language, known as Te Reo, is an integral part of New Zealand's culture. Learning basic Maori words and greetings can enhance your experience in the country.";
export const date = "22 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPXDFZPaqU_DfAKmGNTpWojr2fTLzbCiUtdzDce=s1360-w1360-h1020";
export const url = "basic-maori-words";