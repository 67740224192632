import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function WaitakereRangesRegionalPark() {
    return (
        <section className="waitakererangesregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default WaitakereRangesRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Waitakere Ranges";
export const shortParagraph = "Nestled in the heart of Auckland, Waitakere Ranges Regional Park is a sprawling nature reserve celebrated for its diverse landscapes and outdoor adventures. This vast expanse, covering over 16,000 hectares, offers a harmonious blend of rugged black-sand beaches, where surfers revel in the untamed waves, and lush rainforests with cascading waterfalls that beckon hikers into their verdant embrace.";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipO2V5SWKKKST3ykz-3299ygCK4oagOlPU_bPTtA=s1360-w1360-h1020";
export const photocredits="George Leeper";
export const url = "waitakere-ranges";
export const location = "https://www.google.com/maps/dir//Waitakere+Ranges+Regional+Park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d0d68d9d6529c71:0x54fc03f5e6ece684?sa=X&ved=2ahUKEwiToLKfwcKCAxUVrlYBHQYrAlQQ9Rd6BAhfEAA";
export const address = "300 Scenic Drive, Nihotupu, Auckland 0604";
export const website = "";
export const cordinates = [-36.95211549594084, 174.52887008266038];