import React, { useEffect } from "react";
import BlogPostBanner from "../BlogPostBanner";
import BlogPostInfo from "../BlogPostInfo";
import ShareToSocialMedia from "../ShareToSocialMedia";

function FullLicenceTestRoutes() {
    useEffect(() => {
        document.title = 'New Zealand Class 1 full driver licence Test Routes';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner="https://www.odt.co.nz/sites/default/files/styles/odt_landscape_extra_large_21_10/public/story/2020/05/vtnz_240520.jpg"
                url=""
                photocredits="Peter Mcintosh" />
            <div className="container blog-text my-3 disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Class 1 Full Driver's Licence Test Routes
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 blog-para">
                            It's important to note that official driving test routes are typically not publicly disclosed,
                            and practicing on them may not accurately represent the actual test. To prepare for your driver's license test,
                            it's best to focus on building your driving skills and knowledge of road rules rather than trying
                            to mimic specific test routes.
                        </p>
                        <p>
                            While you may find some general tips and advice online for preparing for a driver's license test,
                            the specific routes used by testing centers are often kept confidential to ensure that candidates
                            are tested fairly and without prior knowledge of the route.
                        </p>
                        <p>
                            If you're preparing for your driver's license test, consider practicing safe driving techniques,
                            studying the official driver's handbook for your area, and seeking guidance from a qualified driving
                            instructor. This will help you become a competent and confident driver, which is essential for passing your test.
                        </p>
                        <h2>General Tips</h2>
                        <p>
                            Here are some general tips to keep in mind when preparing for your driving test:
                        </p>
                        <ul>
                            <li>
                                <strong>Check Blind Spots</strong>: Always perform a head-check to check your blind spots
                                before changing lanes or making turns. This ensures that you're aware of vehicles
                                or obstacles that may not be visible in your mirrors. Failing to check blind spot whenever
                                neccessary is a critical error.
                            </li>
                            <li>
                                <strong>Stop at Stop Signs</strong>: When you approach a stop sign, come to a complete stop.
                                Make sure your vehicle is stationary before proceeding. Failing to stop completely
                                at stop signs can result in penalties.
                            </li>
                            <li>
                                <strong>Drive at Safe Speeds</strong>: Adjust your speed according to road and weather conditions.
                                It's important to drive below the speed limit when necessary, such as during adverse
                                weather or in areas with reduced visibility.
                            </li>
                            <li>
                                <strong>Observe Road Works Speed Limits</strong>: When you encounter road works zones, be sure to slow down and
                                follow the reduced speed limits, often indicated by signs. These limits are enforced for safety,
                                and failing to comply can lead to immediate failure.
                            </li>
                            <li>
                                <strong>Check for Safe Gaps</strong>: Before turning at an intersection or changing lanes, check for safe gaps in traffic.
                                Ensure that there is enough space and time to make the maneuver safely without interfering
                                with other road users.
                            </li>
                            <li>
                                <strong>Be Aware of Other Road Users</strong>: Keep a vigilant eye out for pedestrians, cyclists, motorcyclists, and other
                                vehicles. Always yield the right of way when required and be considerate of other road users.
                            </li>
                        </ul>
                        <p>
                            Below routes are only to have a rough idea on what you will be facing. It is best advised to practise
                            all nearby routes.
                        </p>
                        <h3 className="blue-text">Mt Wellington</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Mt Wellington Silvia Park Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m68!1m12!1m3!1d25517.072921978517!2d174.82313406068346!3d-36.92301162155766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m53!3e0!4m5!1s0x6d0d49497c63cd03%3A0x13417a7964863813!2sVTNZ%20Sylvia%20Park%205%20Sylvia%20Park%20Road%2C%20Mount%20Wellington%2C%20Auckland%201060!3m2!1d-36.9225144!2d174.83903379999998!4m5!1s0x6d0d494af4bde63d%3A0x5c738679bf264906!2sVestey%20Drive%2C%20Mount%20Wellington%2C%20Auckland!3m2!1d-36.9254533!2d174.8373278!4m3!3m2!1d-36.924908099999996!2d174.8415752!4m3!3m2!1d-36.9255512!2d174.8548395!4m5!1s0x6d0d495a81608e35%3A0x59a8088c9943cd21!2sCarbine%20Road%2C%20Mount%20Wellington%2C%20Auckland!3m2!1d-36.9177932!2d174.84713159999998!4m5!1s0x6d0d4956fe7c2011%3A0xaeea56b15a06b5e2!2sGabador%20Place%2C%20Mount%20Wellington%2C%20Auckland%201060!3m2!1d-36.9205946!2d174.8541692!4m5!1s0x6d0d4956924df9d9%3A0x23b117ad42f9994!2sFisher%20Crescent!3m2!1d-36.922094699999995!2d174.854571!4m3!3m2!1d-36.925030899999996!2d174.8467595!4m3!3m2!1d-36.9277964!2d174.83750089999998!4m5!1s0x6d0d49497c63cd03%3A0x13417a7964863813!2sVTNZ%20Sylvia%20Park%2C%20Sylvia%20Park%20Road%2C%20Mount%20Wellington%2C%20Auckland!3m2!1d-36.9225144!2d174.83903379999998!5e0!3m2!1sen!2snz!4v1699483131022!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/hG8thuEn31R2B6J58"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />
                        <h3 className="blue-text">Manukau</h3>
                        {/* <ul>
                            <li>Cavendish Drive</li>
                            <li>Plunket Ave</li>
                            <li>Wiri Station Road</li>
                            <li>Lambie Drive</li>
                            <li>Ranfurly Road</li>
                            <li>Puhinui Road</li>
                        </ul> */}
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Manukau Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m70!1m12!1m3!1d12746.847024081106!2d174.85840615749177!3d-36.992812966503095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m55!3e0!4m5!1s0x6d0d4ddcd656ee1b%3A0xe55ec7a82ee4f135!2s132%20Cavendish%20Drive!3m2!1d-36.9910573!2d174.8668507!4m5!1s0x6d0d4de8c3f419df%3A0xf79debb4048793ca!2sC-Jays%20Services!3m2!1d-36.9989293!2d174.8673765!4m5!1s0x6d0d4d78f5a4acbd%3A0xa8f56979a4663413!2sStation%20Road%20Smart%20Automotive!3m2!1d-36.9978763!2d174.87582609999998!4m3!3m2!1d-36.990773499999996!2d174.8734198!4m3!3m2!1d-36.9894837!2d174.8726093!4m5!1s0x6d0d4ddddc244dd1%3A0x39c2d03407284a56!2s49B%20Ranfurly%20Road%2C%20Papatoetoe%2C%20Auckland!3m2!1d-36.9919301!2d174.8623515!4m3!3m2!1d-36.988498899999996!2d174.8631902!4m5!1s0x6d0d4dc33e03f5e9%3A0xbf7eaa5300efaf3c!2sJ.%20A.%20Russell%20Ltd!3m2!1d-36.98979!2d174.8679888!4m5!1s0x6d0d4ddad44f67af%3A0xe3cdc60a396cfe3!2s112%20Puhinui%20Road!3m2!1d-36.985476299999995!2d174.8669932!4m5!1s0x6d0d4dd959db4251%3A0x97242d3524d819a6!2s4%20Plunket%20Avenue%2C%20Papatoetoe%2C%20Auckland%202104!3m2!1d-36.987407499999996!2d174.8618018!5e0!3m2!1sen!2snz!4v1699482922001!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="d-lg-flex d-grid gap-2 justify-content-center">
                            <a className="btn btn-primary"
                                href="https://goo.gl/maps/e2WN6WfjPKgWWRmx8"
                                target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps - Part 1</a>
                            <a className="btn btn-primary"
                                href="https://goo.gl/maps/E2sW1PFuEDnh5Y5k7"
                                target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps - Part 2</a>
                        </div>
                        <hr />
                        <h3 className="blue-text">New Lynn</h3>
                        {/* <ul>
                            <li>Portage Road</li>
                            <li>Crowther St</li>
                            <li>Taylor St</li>
                            <li>Astley Ave</li>
                            <li>Links Road</li>
                            <li>Cutler St</li>
                            <li>Islington Ave</li>
                            <li>Craigbank Ave</li>
                        </ul> */}
                        <div>
                            <iframe className="embedded-googlemap p-2" title="New Lynn Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m52!1m12!1m3!1d6471.458116323956!2d174.6796583855783!3d-36.919032199880725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m37!3e0!4m5!1s0x6d0d46acc264998b%3A0xd97fe252af88bac8!2sVTNZ%20New%20Lynn%2046%20Portage%20Road%2C%20New%20Lynn%2C%20Auckland%200600!3m2!1d-36.9144842!2d174.6907716!4m5!1s0x6d0d46adc437aadb%3A0x5b76fb07bc4808d!2sBusby%20Street%2C%20Blockhouse%20Bay%2C%20Auckland%200600!3m2!1d-36.9128325!2d174.6942019!4m5!1s0x6d0d4154a77e203f%3A0x19ede45f878a6435!2sLinks%20Road%2C%20New%20Lynn%2C%20Auckland%200600!3m2!1d-36.9169096!2d174.6892053!4m3!3m2!1d-36.9218891!2d174.68639869999998!4m3!3m2!1d-36.9166665!2d174.6808481!4m3!3m2!1d-36.9168533!2d174.6821032!4m5!1s0x6d0d46acc264998b%3A0xd97fe252af88bac8!2sVTNZ%20New%20Lynn%2C%20Portage%20Road%2C%20New%20Lynn%2C%20Auckland!3m2!1d-36.9144842!2d174.6907716!5e0!3m2!1sen!2snz!4v1699480093418!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/DVrLDmMpfhUm88Px9"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />
                        <h3 className="blue-text">Silverdale</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Silverdale Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m44!1m8!1m3!1d12809.472675303592!2d174.6732448!3d-36.6175236!3m2!1i1024!2i768!4f13.1!4m33!3e0!4m5!1s0x6d0d2379f018b189%3A0x91b101dd8aff3df9!2sVTNZ%20Silverdale%205%20Furnace%20Place%2C%20Auckland%200932!3m2!1d-36.624498599999995!2d174.6810009!4m3!3m2!1d-36.622326799999996!2d174.6802611!4m5!1s0x6d0d2375a143d69b%3A0xe6a49818ed723005!2sBrian%20Smith%20Drive%2C%20Silverdale%200992!3m2!1d-36.6207183!2d174.6716155!4m5!1s0x6d0d239b351be639%3A0x27a50be0f857e5ef!2sCentral%20Boulevard%2C%20Silverdale%200932!3m2!1d-36.613323699999995!2d174.6814695!4m3!3m2!1d-36.618621999999995!2d174.6811021!4m5!1s0x6d0d2379f018b189%3A0x91b101dd8aff3df9!2sVTNZ%20Silverdale%20Furnace%20Place%2C%20Auckland!3m2!1d-36.624498599999995!2d174.6810009!5e0!3m2!1sen!2snz!4v1699483580844!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/hawaNiUcvJhDnGr37"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />
                        <h3 className="blue-text">Glen Innes</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Glen Innes Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m48!1m12!1m3!1d25529.488894037306!2d174.83373251057353!3d-36.88589572222656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m33!3e0!4m3!3m2!1d-36.8792155!2d174.8678271!4m3!3m2!1d-36.8854624!2d174.8669639!4m3!3m2!1d-36.872731699999996!2d174.8541765!4m5!1s0x6d0d4bd96aad6539%3A0xe8195cd07823600!2sCaen%20Road%2C%20Panmure%2C%20Auckland!3m2!1d-36.9005411!2d174.86110779999998!4m3!3m2!1d-36.901710699999995!2d174.8568647!4m3!3m2!1d-36.8750668!2d174.84269609999998!4m5!1s0x6d0d49889b4692a1%3A0xf2c50624dbc3f9a2!2sVTNZ%20Glen%20Innes!3m2!1d-36.8748788!2d174.8535233!5e0!3m2!1sen!2snz!4v1699483863312!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/kqqMyYRhyN7A1V7BA"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />
                        <h3 className="blue-text">Highbrook</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Highbrook Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m62!1m8!1m3!1d25508.94973638988!2d174.8832699!3d-36.9472775!3m2!1i1024!2i768!4f13.1!4m51!3e0!4m5!1s0x6d0d4c773dc6c437%3A0x837bf57bd6ff07dc!2sVTNZ%20Highbrook!3m2!1d-36.9353957!2d174.8869033!4m3!3m2!1d-36.9312575!2d174.89048219999998!4m5!1s0x6d0d4c797aa77da3%3A0x30eb8bd031cc6527!2sNeales%20Road%2C%20East%20T%C4%81maki%2C%20Auckland!3m2!1d-36.9352508!2d174.8909055!4m3!3m2!1d-36.9360577!2d174.8953738!4m5!1s0x6d0d4c88b0fd0687%3A0xe69005ab5e1a982f!2sSmales%20Road%2C%20East%20T%C4%81maki%2C%20Auckland!3m2!1d-36.9421031!2d174.9029481!4m3!3m2!1d-36.9458365!2d174.90741359999998!4m5!1s0x6d0d4c58c95fadbb%3A0xc11d1295d8995eac!2sTe%20Irirangi%20Drive%2C%20Clover%20Park%2C%20Auckland!3m2!1d-36.959248699999996!2d174.9032302!4m3!3m2!1d-36.937560999999995!2d174.8756344!4m3!3m2!1d-36.936259!2d174.8851383!4m5!1s0x6d0d4c773dc6c437%3A0x837bf57bd6ff07dc!2sVTNZ%20Highbrook!3m2!1d-36.9353957!2d174.8869033!5e0!3m2!1sen!2snz!4v1699484084229!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/XKJ4r1p44FFVAL689"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <div className="mt-2">
                            <iframe className="embedded-googlemap p-2" title="Highbrook Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m50!1m12!1m3!1d12756.59059446273!2d174.88142436136687!3d-36.93463885534202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m35!3e0!4m5!1s0x6d0d4c773dc6c437%3A0x837bf57bd6ff07dc!2sVTNZ%20Highbrook%2033%20Cryers%20Road%2C%20Highbrook%2C%20Auckland%202013!3m2!1d-36.9353957!2d174.8869033!4m3!3m2!1d-36.9297855!2d174.8848879!4m5!1s0x6d0d4c79f60931cd%3A0x9a1afdaa2d7f62b5!2sBruce%20Roderick%20Drive%2C%20East%20T%C4%81maki%2C%20Auckland%202013!3m2!1d-36.9366781!2d174.88626979999998!4m5!1s0x6d0d4b7feed1e26d%3A0x93325f726fbb05e!2sNandina%20Avenue%2C%20East%20T%C4%81maki%2C%20Auckland%202013!3m2!1d-36.9299283!2d174.8996063!4m5!1s0x6d0d4c7af1fe30df%3A0xe01896bb8389286a!2sCrooks%20Road%2C%20East%20T%C4%81maki%2C%20Auckland%202013!3m2!1d-36.9390224!2d174.8896577!4m5!1s0x6d0d4c773dc6c437%3A0x837bf57bd6ff07dc!2sVTNZ%20Highbrook%2C%20Cryers%20Road%2C%20Highbrook%2C%20Auckland!3m2!1d-36.9353957!2d174.8869033!5e0!3m2!1sen!2snz!4v1699484180425!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/8EG3x5vJaQSBokGQ9"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />
                        <h3 className="blue-text">Wiri</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Wiri Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m62!1m12!1m3!1d25490.736075267687!2d174.84410401091648!3d-37.001636420150604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m47!3e0!4m5!1s0x6d0d4e08d62de98b%3A0xd59bab681e36b7b1!2sVTNZ%20Wiri!3m2!1d-37.001532499999996!2d174.8515042!4m3!3m2!1d-37.002638!2d174.8506118!4m3!3m2!1d-37.0106442!2d174.85802859999998!4m3!3m2!1d-37.0093761!2d174.8617897!4m5!1s0x6d0d4deed33c2f59%3A0x76858dd9aadd7b34!2sWiri%20Station%20Road%2C%20Wiri%2C%20Auckland%202104!3m2!1d-37.001221799999996!2d174.8672368!4m3!3m2!1d-37.005102099999995!2d174.87932429999998!4m3!3m2!1d-37.0041874!2d174.8750671!4m3!3m2!1d-37.0012097!2d174.873456!4m3!3m2!1d-37.001637599999995!2d174.87042459999998!4m5!1s0x6d0d4e08d62de98b%3A0xd59bab681e36b7b1!2sVTNZ%20Wiri!3m2!1d-37.001532499999996!2d174.8515042!5e0!3m2!1sen!2snz!4v1699484311155!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/TjyFzLvdHayNMzNw6"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />

                        <h3 className="blue-text">Pukehoke</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="Pukehoke Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m62!1m12!1m3!1d12710.22479678968!2d174.89371736157125!3d-37.210769304123154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m47!3e0!4m5!1s0x6d12aa92561e108d%3A0x7f2aa40ef87a9ec4!2sVTNZ%20Pukekohe!3m2!1d-37.2069705!2d174.91073169999999!4m5!1s0x6d12aa92bcf16d2b%3A0x28839e53093ab7b2!2sManukau%20Road%2C%20Pukekohe%2C%20Pukekohe%20Hill%202120!3m2!1d-37.208717199999995!2d174.9098349!4m3!3m2!1d-37.2077661!2d174.90680849999998!4m3!3m2!1d-37.217438!2d174.89741709999998!4m3!3m2!1d-37.204166799999996!2d174.897529!4m3!3m2!1d-37.204243399999996!2d174.8996982!4m3!3m2!1d-37.2043855!2d174.9008912!4m3!3m2!1d-37.212274199999996!2d174.9106203!4m3!3m2!1d-37.216946799999995!2d174.89960979999998!4m5!1s0x6d12aa92561e108d%3A0x7f2aa40ef87a9ec4!2sVTNZ%20Pukekohe!3m2!1d-37.2069705!2d174.91073169999999!5e0!3m2!1sen!2snz!4v1699484460414!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/w33YShHaBUMTj2Wv5"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                        <hr />

                        <h3 className="blue-text">North Shore</h3>
                        <div>
                            <iframe className="embedded-googlemap p-2" title="North Shore Driving Test Route"
                                src="https://www.google.com/maps/embed?pb=!1m74!1m12!1m3!1d25562.197381548667!2d174.7111689102841!3d-36.78796427400574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m59!3e0!4m5!1s0x6d0d39a5f90e31a1%3A0x35c795b0d73b5699!2sVTNZ%20North%20Shore!3m2!1d-36.784182!2d174.7365814!4m5!1s0x6d0d39a883371b9f%3A0x16ddfdf15c4601a2!2sArchers%20Road%2C%20Hillcrest%2C%20Auckland!3m2!1d-36.7843827!2d174.73472329999998!4m5!1s0x6d0d39a9ef2af385%3A0x541a6ccc6b3493f5!2sBeatrice%20Avenue%2C%20Hillcrest%2C%20Auckland!3m2!1d-36.7884759!2d174.7320422!4m5!1s0x6d0d39aa215cbaaf%3A0xf2c7e98ca68d1ab4!2sCoronation%20Road%2C%20Auckland!3m2!1d-36.789757!2d174.733316!4m5!1s0x6d0d3905445ad081%3A0x9eb569f358445628!2sGlenfield%20Road%2C%20Auckland!3m2!1d-36.7844924!2d174.7218964!4m5!1s0x6d0d3904dedc3d0d%3A0x7ffd4af10d83df9e!2sHigh%20Road%2C%20Glenfield%2C%20Auckland!3m2!1d-36.7865184!2d174.7227317!4m5!1s0x6d0d39ab06c28d4f%3A0x7cefdd061d579747!2sLynden%20Avenue%2C%20Hillcrest%2C%20Auckland!3m2!1d-36.7923818!2d174.73498669999998!4m5!1s0x6d0d39aa82e7fe39%3A0x27ab2b2ee91473c2!2sMountbatten%20Avenue!3m2!1d-36.7931824!2d174.73238089999998!4m3!3m2!1d-36.7875225!2d174.7428577!4m5!1s0x6d0d39a5f90e31a1%3A0x35c795b0d73b5699!2sVTNZ%20North%20Shore!3m2!1d-36.784182!2d174.7365814!5e0!3m2!1sen!2snz!4v1699484561452!5m2!1sen!2snz"
                                style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <div className="d-block text-center">
                                <a className="btn btn-primary"
                                    href="https://maps.app.goo.gl/rCQUrEDfBgtdiCkt6"
                                    target="_blank" rel="noopener noreferrer" role="button">Open in Google Maps</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default FullLicenceTestRoutes;

export const title = "New Zealand Class 1 Full Driver's licence Test Routes";
export const shortParagraph = "We'll walk you through the essential steps and provide you with some tips for a smooth test process of New Zealand Class 1 (car) full drivers licence";
export const date = "08 November 2023";
export const image = "https://www.odt.co.nz/sites/default/files/styles/odt_landscape_extra_large_21_10/public/story/2020/05/vtnz_240520.jpg";
export const url = "new-zealand-full-licence-test-routes";