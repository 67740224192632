import React from "react";
import ClothBanner from "../assets/images/Shop-Banner.png";

function ShopBanner() {
    return (
        <section className="shopbanner my-5">
            <div className="text-white text-center p-5"
                style={{
                    background: `url(${ClothBanner})`, backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", backgroundPosition: "center", height: "500px"
                }}>
            </div>
        </section>
    );
}

export default ShopBanner;