import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import iosBadge from "../../assets/images/Download_on_the_App_Store_Badge.png";

function AppWidget(props) {
    return (
        <div className="card mb-3 blog-card p-3" style={{ backgroundColor: "#087EA6" }}>
            <div className="row g-0">
                <div className="col-4" style={{ maxWidth: "120px", maxHeight: "120px" }} >
                    <img src={props.image} className="img-fluid rounded-start" />
                </div>
                <div className="col-8">
                    <div className="card-body py-0">
                        <div className="p-2">
                            <h5 className="card-title text-white fs-3 fw-bold">{props.title}</h5>
                            <p className="card-text text-white">{props.company}</p>
                        </div>
                        <p className="card-text">
                            <div className="p-2">
                                <a
                                    href={props.android}>
                                    <img alt='Get it on Google Play' className="h-lg-25 w-lg-25 h-50 w-50"
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                </a>
                                <a
                                    href={props.ios}>
                                    <img alt='Get it on App Store' className="h-50 w-50"
                                    // style={{height:"50px", width:""}}
                                        src={iosBadge} />
                                </a>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AppWidget;