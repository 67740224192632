import React, { useEffect } from "react";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function NzSeasons() {
    useEffect(() => {
        document.title = 'New Zealand Seasonal Calendar';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="Ian Wang" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    New Zealand Seasons Guide
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 ">
                            Here's a concise guide to the enchanting seasons of Aotearoa:
                        </p>

                        <table className="table table-success table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">SEASON</th>
                                    <th scope="col">MONTHS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Summer</td>
                                    <td>Dec, Jan, Feb</td>
                                </tr>
                                <tr>
                                    <td>Autumn</td>
                                    <td>Mar, Apr, May</td>
                                </tr>
                                <tr>
                                    <td>Winter</td>
                                    <td>Jun, Jul, Aug</td>
                                </tr>
                                <tr>
                                    <td>Spring</td>
                                    <td>Sep, Oct, Nov</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>
                            Plan your activities wisely by checking the <a href="https://www.metservice.com/" className="linked-text">MetService</a> website
                            for the latest and most precise weather updates tailored to your location.
                        </p>


                        <h3 className="blue-text">1. Spring (September-November)</h3>
                        <p>

                        </p>
                        <ul>
                            <li>Blooming landscapes and mild temperatures.</li>
                            <li>Ideal for hiking, wildlife encounters, and witnessing vibrant flora.</li>
                        </ul>

                        <h3 className="blue-text">2. Summer (December-February)</h3>
                        <p>

                        </p>
                        <ul>
                            <li>Warm temperatures and long daylight hours.</li>
                            <li>Perfect for beach activities, water sports, and exploring national parks.</li>
                            {/* <li>School holidays. There is also a shut down period of 1-2 weeks for most companies, banks from Dec last week to Jan first week.</li> */}
                            {/* <li>Peak Travel Season</li> */}
                            {/* Drop sunrise and sunset time, about the clock */}
                            {/* Make bookings in advance as room rates will be higher */}
                            <li>20-25°C</li>
                        </ul>



                        <h3 className="blue-text">3. Autumn (March-May)</h3>
                        <p>

                        </p>
                        <ul>
                            <li>Stunning fall foliage and pleasant temperatures.</li>
                            <li>Great for wine tours, harvesting festivals, and scenic drives.</li>
                        </ul>

                        <h3 className="blue-text">4. Winter (June-August)</h3>
                        <p>

                        </p>
                        <ul>
                            <li>Snow-capped mountains and crisp air.</li>
                            <li>Ideal for skiing, snowboarding, and enjoying cozy retreats.</li>
                            {/* few activities will be closed for access */}
                            {/* school holidays for 2 weeks in middle of July */}
                            {/* 12-16°C */}
                            {/* Peak season for ski-fileds */}
                        </ul>


                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default NzSeasons;

export const title = "The Seasonal Calendar of New Zealand";
export const shortParagraph = "New Zealand boasts diverse landscapes and experiences throughout the year. Understanding the seasons is crucial for planning an unforgettable trip.";
export const date = "21 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPGOyQvvypLg7lAQypepaFdE-QR7OE59GgABwaD=s1360-w1360-h1020";
export const url = "newzealand-seasons-guide";