import React, { useState } from "react";

function AucklandEvents() {
    // State to track the active navigation item
    const [activeNavItem, setActiveNavItem] = useState("all");

    const handleActiveItem = (item) => {
        setActiveNavItem(item);
    };

    return (
        <section>
            <div className="">
                <h1>Events</h1>
            </div>
        </section>
    );
}

export default AucklandEvents;