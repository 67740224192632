import React, { useState } from "react";

function WageCalculator() {
    const [hourlySalary, setHourlySalary] = useState("");
    const [dailySalary, setDailySalary] = useState("");
    const [weeklySalary, setWeeklySalary] = useState("");
    const [biweeklySalary, setBiweeklySalary] = useState("");
    const [monthlySalary, setMonthlySalary] = useState("");
    const [annualSalary, setAnnualSalary] = useState("");
    const [showSalary, setShowSalary] = useState(false);
    const [showDaysPerWeek, setShowDaysPerWeek] = useState(false);
    const [timeType, setTimeType] = useState("week");
    const [state, setState] = useState("equal");
    const medianWage= parseFloat("29.66");

    const handleSalaryCalculation = (event) => {
        'use strict'
        event.preventDefault();

        const formData = new FormData(event.target);

        const from = formData.get('fromTypeSelect');
        const rate = parseFloat(formData.get("rate"));
        const hours = parseFloat(formData.get("hourspertime"));
        const time = formData.get("timeTypeSelect");
        const days = formData.get("daysTypeSelect");

        if (from === "Hourly" && time === "day") {
            setHourlySalary(rate.toFixed(2));
            setDailySalary((rate * hours).toFixed(2));
            setWeeklySalary((rate * hours * days).toFixed(2)); 
            setBiweeklySalary((rate * hours * days * 2).toFixed(2)); 
            setMonthlySalary((rate * hours * days * 4.33).toFixed(2));
            setAnnualSalary((rate * hours * days * 52).toFixed(2));
        } else if (from === "Hourly" && time === "week") {
            setHourlySalary(rate.toFixed(2));
            setDailySalary("NA"); 
            setWeeklySalary((rate * hours).toFixed(2));
            setBiweeklySalary((rate * hours * 2).toFixed(2));
            setMonthlySalary((rate * hours * 4.33).toFixed(2));
            setAnnualSalary((rate * hours * 52).toFixed(2));
        } else if (from === "Daily" && time === "day") {
            setHourlySalary((rate / hours).toFixed(2));
            setDailySalary(rate.toFixed(2));
            setWeeklySalary((rate * days).toFixed(2));
            setBiweeklySalary((rate * days * 2).toFixed(2));
            setMonthlySalary((rate * days * 4.33).toFixed(2));
            setAnnualSalary((rate * days * 52).toFixed(2));
        } else if (from === "Daily" && time === "week") {
            setHourlySalary("NA");
            setDailySalary(rate.toFixed(2));
            setWeeklySalary((rate * hours).toFixed(2));
            setBiweeklySalary((rate * hours * 2).toFixed(2));
            setMonthlySalary((rate * hours * 4.33).toFixed(2));
            setAnnualSalary((rate * hours * 52).toFixed(2));
        } else if (from === "Weekly" && time === "day") {
            setHourlySalary(((rate / days) / hours).toFixed(2));
            setDailySalary((rate / days).toFixed(2));
            setWeeklySalary(rate.toFixed(2));
            setBiweeklySalary((rate * 2).toFixed(2));
            setMonthlySalary((rate * 4.33).toFixed(2));
            setAnnualSalary((rate * 52).toFixed(2));
        } else if (from === "Weekly" && time === "week") {
            setHourlySalary((rate / hours).toFixed(2));
            setDailySalary("NA");
            setWeeklySalary(rate.toFixed(2));
            setBiweeklySalary((rate * 2).toFixed(2));
            setMonthlySalary((rate * 4.33).toFixed(2));
            setAnnualSalary((rate * 52).toFixed(2));
        } else if (from === "Biweekly" && time === "day") {
            setHourlySalary((rate / (hours * 2 * days)).toFixed(2)); 
            setDailySalary((rate / (2 * days)).toFixed(2)); 
            setWeeklySalary((rate / 2).toFixed(2));
            setBiweeklySalary((rate).toFixed(2));
            setMonthlySalary((rate * 2).toFixed(2));
            setAnnualSalary((rate * 24).toFixed(2));
        } else if (from === "Biweekly" && time === "week") {
            setHourlySalary((rate / (hours * 2)).toFixed(2)); 
            setDailySalary("NA"); 
            setWeeklySalary((rate / 2).toFixed(2));
            setBiweeklySalary((rate).toFixed(2));
            setMonthlySalary((rate * 2).toFixed(2));
            setAnnualSalary((rate * 24).toFixed(2));
        } else if (from === "Monthly" && time === "day") {
            setHourlySalary((rate / (hours * 4.33 * days)).toFixed(2));
            setDailySalary((rate / (4.33 * days)).toFixed(2));
            setWeeklySalary((rate / 4.33).toFixed(2));
            setBiweeklySalary((rate / 2).toFixed(2));
            setMonthlySalary((rate).toFixed(2));
            setAnnualSalary((rate * 12).toFixed(2));
        } else if (from === "Monthly" && time === "week") {
            setHourlySalary((rate / (hours * 4.33)).toFixed(2));
            setDailySalary((rate / (4.33 * 5)).toFixed(2));
            setWeeklySalary((rate / 4.33).toFixed(2));
            setBiweeklySalary((rate / 2).toFixed(2));
            setMonthlySalary((rate).toFixed(2));
            setAnnualSalary((rate * 12).toFixed(2));
        } else if (from === "Annually" && time === "day") {
            setHourlySalary((rate / (hours * 52)).toFixed(2));
            setDailySalary(((rate / (hours * 52))*8).toFixed(2));
            setWeeklySalary((rate / 52).toFixed(2));
            setBiweeklySalary((rate / 26).toFixed(2));
            setMonthlySalary((rate / 12).toFixed(2));
            setAnnualSalary(rate.toFixed(2));
        } else if (from === "Annually" && time === "week") {
            setHourlySalary((rate / (hours * 52)).toFixed(2));
            setDailySalary(((rate / (hours * 52))*8).toFixed(2));
            setWeeklySalary((rate / 52).toFixed(2));
            setBiweeklySalary((rate / 26).toFixed(2));
            setMonthlySalary((rate / 12).toFixed(2));
            setAnnualSalary(rate.toFixed(2));
        }

        if (hourlySalary > medianWage) {
            setState("higher than");
        } else if(hourlySalary < medianWage) {
            setState("lower than");
        } else {
            setState("same as");
        }

        setShowSalary(true);

    };

    const handleDaysOrWeek = (event) => {
        setTimeType(event.target.value);
        if (event.target.value === "day") {
            setShowDaysPerWeek(true);
        } else {
            setShowDaysPerWeek(false);
        }
    };

    const validateDecimalInput = (event) => {
        const value = event.target.value;

        // Regular expression to match decimal numbers with up to 2 decimal places
        const decimalPattern = /^(\d*\.?\d{0,2})$/;

        if (decimalPattern.test(value) || value === "") {
            // If the input is valid or empty, update the state
            event.target.setCustomValidity("");
        } else {
            // If the input is invalid, show a custom validation message
            event.target.setCustomValidity("Decimal part should be between 0 and 59");
        }
    };

    return (
        <section className="wagecalculator">
            <div className="container p-5 mb-5" style={{ boxShadow: "0px 0px 10px rgb(166 101 209 / 20%)", marginTop: "80px" }}>
                <h1 className="mb-5 text-center">New Zealand Wage Calculator</h1>
                <p className="text-center">Use this super-easy tool to calculate your wage online!</p>
                <div className="d-flex justify-content-center mt-5">
                    <form className="p-3 w-lg-25" style={{ boxShadow: "0px 0px 10px rgb(166 101 209 / 20%)" }}
                        onSubmit={handleSalaryCalculation}>
                        <div className="d-flex my-3">
                            <div className="col">
                                <select className="form-select" aria-label="Select from" defaultValue="Hourly" name="fromTypeSelect">
                                    <option value="Hourly">Hourly</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Biweekly">Biweekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-3">
                                <h5 className="mb-2">Salary</h5>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input className="form-control" type="number" placeholder="$22.70" id="inputRate" name="rate"
                                        defaultValue="22.70" step="0.01" aria-label="input rate" />
                                </div>
                            </div>
                            <div className="mb-3">
                                <h5 className="mb-2">Hours of Work</h5>
                                <div className="input-group">
                                    <input className="form-control" type="number" placeholder="40 Hours"
                                        id="inputHours" name="hourspertime"
                                        defaultValue="40" aria-label="input hours"
                                        min={timeType === "day" ? 0 : 0}
                                        max={timeType === "day" ? 24 : 168} 
                                        // step="0.01"
                                        // pattern="^(\d*\.?\d{0, 2})$"
                                        // onChange={validateDecimalInput}
                                        />
                                    
                                    <span className="input-group-text">hours per</span>

                                    <select className="form-select" aria-label="Time" defaultValue="week"
                                        name="timeTypeSelect" onChange={handleDaysOrWeek}>
                                        <option value="day">day</option>
                                        <option value="week">week</option>
                                    </select>
                                </div>
                            </div>
                            {
                                showDaysPerWeek && (
                                    <div className="daysperweek mb-3">
                                        <h5 className="mb-2">Days per Week</h5>
                                        <div className="input-group">
                                            <select className="form-select" aria-label="Days" defaultValue="5" name="daysTypeSelect">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                            </select>
                                            <span className="input-group-text">days a week</span>

                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                {
                    showSalary && (
                        <div className="my-3 d-block justify-items-center">
                            <div>
                                <p className="text-center">
                                    Currently, the median wage per hour in New Zealand is NZD$29.66.
                                    Your income is {state} the median hourly wage.
                                </p>
                            </div>
                            <div className="text-center">
                                <table className="table table-striped w-50 mx-auto">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Salary</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Hourly</td>
                                            <td>${hourlySalary}</td>
                                        </tr>
                                        <tr>
                                            <td>Daily</td>
                                            <td>${dailySalary}</td>
                                        </tr>
                                        <tr>
                                            <td>Weekly</td>
                                            <td>${weeklySalary}</td>
                                        </tr>
                                        <tr>
                                            <td>Biweekly</td>
                                            <td>${biweeklySalary}</td>
                                        </tr>
                                        <tr>
                                            <td>Monthly</td>
                                            <td>${monthlySalary}</td>
                                        </tr>
                                        <tr>
                                            <td>Annually</td>
                                            <td>${annualSalary}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    );
}

export default WageCalculator;