import React from "react";

function BlogPostBanner(props) {
    return (
        <div className="position-relative banner" style={{ backgroundImage: `url(${props.banner})` }}>
            <div className="position-absolute bottom-0 end-0 text-white">
                <p className="p-2">Photo by <a href={props.url} target="_blank"
                    className="text-decoration-none text-white">{props.photocredits}</a></p>
            </div>
        </div>
    );
}

export default BlogPostBanner;