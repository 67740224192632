import React, { useState } from "react";
import Kakashi from "../assets/images/asilentexplorerbanner.png";

function TheTeam() {
    return (
        <section className="theteam">
            <div className="banner" style={{ backgroundImage: `url(${Kakashi})` }}></div>
            <div className="container p-5">
                <h1 className="text-center">About Us</h1>
                <p className="mt-5 text-center">
                    Hello, we're two techie souls who've found our sanctuary in beautiful New Zealand.
                    For us, there's nothing quite like savoring the pure moments that travel offers. 
                </p>
                <p className="text-center">
                    The boy is a huge anime fan, with Kakashi Hatake as his spirit character. 
                    So yes, there might just be a sprinkle of anime magic along the way!
                    Unlike Kakashi's solitary journey in the anime, our hero discovers love.
                    The couple shared dreams and love for travel and peace.
                    From Kakashi's passion to taking his beloved girl to her favorite spots,
                    they've woven a tale that has become "asilentexplorer".
                </p>
                <p className="text-center">
                    In our exploration of this stunning land, we've realized that we'd rather collect intimate memories
                    than join the crowd.
                </p>
                <p className="text-center">
                    As we traverse the landscapes, you'll often find us with a cup of tea in hand – it's our go-to comfort.
                </p>
                <p className="text-center">
                    Join us as we journey through New Zealand, capturing these treasured moments one by one.
                </p>


            </div>
        </section >
    );
}

export default TheTeam;