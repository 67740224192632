import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function OnekawaTeMawhaiRegionalPark() {
    return (
        <section className="onekawatemawhairegionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default OnekawaTeMawhaiRegionalPark;

export const tags = ["regionalpark", "bay-of-plenty", "north-island"];
export const title = "Onekawa Te Mawhai";
export const shortParagraph = "Onekawa Te Mawhai Regional Park is a protected area located in Ōhiwa, Bay of Plenty Region. It offers a culturally rich and diverse landscape, featuring significant pā sites and a history of human settlement. The park provides an easy walk from Ohiwa Seascape Studios, offering stunning views over the Pacific Ocean. For those interested in exploring the park, there is a loop walk available, providing an opportunity to experience its natural beauty";
export const date = "16 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipPf63jIP-65ZPVRTxZF16RKiacEQJTMIYs67p87=s1360-w1360-h1020";
export const photocredits="Praveen Kumar";
export const url = "onekawa-te-mawhai";
export const location = "https://www.google.com/maps/dir//onekawa+te+mawhai+regional+park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d6f0bb3bc11ca83:0xd7958b089d25e7a6?sa=X&ved=2ahUKEwjRseixgceCAxUcTGwGHXPVDhAQ9Rd6BAhBEAA&sqi=2";
export const address = "20 Bryans Road, Waiotahe 3198";
export const website = "";
export const cordinates = [-37.99011878291013, 177.16908849621055];