import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function MotukoreaBrownsIsland() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default MotukoreaBrownsIsland;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Motukorea Browns Island Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPvsAZ5TWfJNf6dTqVhlu124WXBZVNPB13RPGBE=s1360-w1360-h1020";
export const photocredits = "Juliano Baby";
export const url = "motukorea-browns-island";
export const location = "https://www.google.com/maps/place/Browns+Island+%2F+Motukorea/@-36.8313808,174.8944269,16z/data=!3m1!4b1!4m6!3m5!1s0x6d0d359c8a6250ff:0xf00ef62249c0580!8m2!3d-36.8299845!4d174.8945709!16zL20vMGJ5Mnlz?entry=ttu";
export const address = "The Parade, Bucklands Beach, Auckland";
export const website = "";
export const cordinates = [-36.82664927278045, 174.89548937324588];