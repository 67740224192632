import React from "react";

function DestinationBanner(props) {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{
            backgroundImage: `url(${props.banner})`, backgroundRepeat: "no-repeat",
            backgroundSize: "cover", backgroundPosition: "center", height: "500px"
        }}>
            <div className="d-block text-white">
                <p className="text-center">WELCOME TO</p>
                <h1 className="text-uppercase fw-bold fs-1">{props.destination}</h1>
            </div>
        </div>
    );
}

export default DestinationBanner;