import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spring4 from "../../assets/images/pexels-2344013.jpg";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Spring5 from "../../assets/images/pexels-kevin-b-18545526.jpg";
import Cornwall from "../../assets/images/IMG_1133.jpg";
import BotanicGarden from "../../assets/images/IMG_1135.jpg";
import VictoriaEsplanade from "../../assets/images/Victoria Esplanade.jpg";
import HagleyPark from "../../assets/images/hagley-park-christchurch.jpg";
import ChristChurchBotaniGardens from "../../assets/images/christchurchbotanicgardens.jpg";
import CherryBlossomFestival from "../../assets/images/cherryblossomfestival.jpg";
import MatakanaVillage from "../../assets/images/matakana-village-cherry-blossom.jpg";
import Pukehoke from "../../assets/images/pukehoke.jpg";
import Otago from "../../assets/images/otagouniversity.jpg";
import Irresistiblue from "../../assets/images/Irresistiblue.png";
import Canterbury from "../../assets/images/universityofcanterbury.jpg";
import Jansen from "../../assets/images/jansen.jpg";
import Aston from "../../assets/images/aston.jpg";
import Dress1 from "../../assets/images/812LnxdwqDL._AC_UX569_.jpg";
import Dress2 from "../../assets/images/71h+TgGqo5L._AC_UX466_.jpg";
import Dress3 from "../../assets/images/81eUDniiifL._AC_UY741_.jpg";
import Dress4 from "../../assets/images/81rMNGa05nL._AC_UY741_.jpg";
import Dress5 from "../../assets/images/7148gLADfhS._AC_UX679_.jpg";
import Dress6 from "../../assets/images/710IW+JwLHL._AC_UX679_.jpg";
import Tripod from "../../assets/images/tripod.jpg";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";
import AffliateDisclosure from "../AffliateDisclosure";


function NzSpring2023() {
    useEffect(() => {
        document.title = 'Chasing Cherry Blossom in New Zealand Spring 2023';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={Spring4}
                url="https://www.pexels.com/photo/photo-of-girl-wearing-bucket-hat-2344013/"
                photocredits="三 点sky" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a></li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a></li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Cherry Blossom</li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="8" />
                        <AffliateDisclosure />
                        <p className="mt-3 blog-para">
                            Here are some places where you can experience the magic of cherry blossoms:
                        </p>
                        <hr />
                        <h2 className="fw-bold">Hamilton</h2>
                        <hr />
                        <h3 className="mt-2">Hamilton Gardens</h3>
                        <img src={Spring5}
                            className="mt-2 w-100"
                            alt="Photo by Kevin B: https://www.pexels.com/photo/blooming-pink-flowers-on-the-branches-of-a-cherry-tree-18545526/" />
                        <p className="mt-3">
                            The cherry blossom trees in the Hamilton Gardens are a sight to behold during
                            spring, with a variety of cherry blossom species that bloom in succession. This makes it a prime
                            spot for cherry blossom enthusiasts.
                        </p>
                        <h3>NZ Cherry Blossom Festival, Hamilton</h3>
                        <img src={CherryBlossomFestival}
                            className="mt-2 w-100" alt="NZ Cherry Blossom Festival, Hamilton" />
                        <p className="mt-3">
                            This festival celebrates the cherry blossoms in Hamilton and offers a delightful cherry blossom viewing
                            experience. It's an annual event that shouldn't be missed.
                        </p>
                        <h3>Jansen Park</h3>
                        <img src={Jansen}
                            className="mt-2 w-100" alt="Jansen Park, Hamilton" />
                        <p className="mt-3">
                            Jansen Park, Hamilton is one of the top spots to enjoy cherry blossoms in the Waikato.
                        </p>
                        <p>
                            Location: Morris Road, Silverdale, Hamilton 3216
                        </p>
                        <h3>Cafe Irresistiblue</h3>
                        <img src={Irresistiblue}
                            className="mt-2 w-100" alt="Cafe Irresistiblue" />
                        <p className="mt-3">
                            Your Central Waikato Retreat. Located just a short drive from Cambridge, Te Awamutu, and Hamilton,
                            Cafe Irresistiblue offers a serene escape. Enjoy their all-day menu,
                            cabinet food, a wide drink selection, and delectable desserts. No dogs allowed.
                            Open seven days a week from 9 am to 4:30 pm; reservations recommended at 07 834 3501.
                        </p>
                        <hr />
                        <h2 className="fw-bold">Palmerston North</h2>
                        <hr />
                        <h3 className="mt-2">Victoria Esplanade Gardens</h3>
                        <img src={VictoriaEsplanade}
                            className="mt-2 w-100" alt="Victoria Esplanade Gardens" />

                        <p className="mt-3">
                            This beautiful garden is home to an enchanting cherry blossom grove,
                            providing a serene setting for cherry blossom viewing.
                        </p>

                        <hr />
                        <h2 className="fw-bold">Auckland</h2>
                        <hr />
                        <h3 className="mt-2">
                            Cornwall Park
                        </h3>
                        <img src={Cornwall}
                            className="mt-2 w-100" alt="Cornwall Park" />
                        <p className="mt-3">
                            Located in the heart of Auckland, Cornwall Park features cherry
                            blossom trees that create a picturesque scene during spring.
                            These delicate blossoms grace two picturesque locations –
                            a stunning display awaits you along the scenic Pōhutukawa Drive,
                            while a quieter, more intimate collection can be found along Pūriri Drive.
                        </p>
                        <h3 className="mt-2">
                            Auckland Botanic Garden
                        </h3>
                        <img src={BotanicGarden}
                            className="mt-2 w-100" alt="Auckland Botanic Garden" />
                        <p className="mt-3">
                            The Auckland Botanic Gardens is a renowned destination, featuring a diverse collection
                            of over 10,000 plant species sourced from around the world. Every year,
                            it draws a large number of visitors, and its popularity is well-justified.
                            As you explore the gardens, you'll find yourself surrounded by the breathtaking
                            beauty of native forests and an array of stunning flowers that enhance the overall
                            charm of this botanical haven. Notably, during the spring season, the cherry blossom
                            trees in the Auckland Botanic Gardens create a captivating spectacle that's truly
                            awe-inspiring. This annual display of cherry blossoms is a must-see event,
                            offering a delightful and visually stunning experience for all who visit.
                        </p>
                        <h3 className="mt-2">
                            Eden Garden & Cafe
                        </h3>
                        <p>
                            Explore an enchanting garden that boasts a remarkable array of plants,
                            featuring rare and exotic species sourced from across the globe.
                            As you wander through this botanical paradise, you'll encounter an impressive
                            collection of flora that will captivate your senses. And when it's time to
                            take a relaxing break, the garden offers an on-site café where you can savor
                            delectable treats. While enjoying these culinary delights, you can bask in the
                            tranquil ambiance of the garden's serene surroundings. It's a delightful
                            and harmonious experience for all visitors,
                            where nature's beauty and culinary delights combine to create a memorable outing.
                        </p>
                        <h3 className="mt-2">
                            WhiteCliffs Drive
                        </h3>
                        <p>
                            Experience the serene beauty of WhiteCliffs Drive in Waiau Pa,
                            a tranquil escape just 40 minutes away from the bustling heart of Auckland's CBD.
                        </p>
                        <h3 className="mt-2">
                            Matakana Village
                        </h3>
                        <img src={MatakanaVillage}
                            className="mt-2 w-100" alt="Matakana Village" />
                        <p className="mt-3">
                            Experience the enchanting sight of the Awanui Cherry Blossoms in full bloom at Matakana Village.
                            Don't miss this fleeting moment to revel in the beauty of pink blossoms,
                            making your visit to the village a truly memorable and picturesque experience.
                        </p>
                        <h3 className="mt-2">
                            Pukekohe Town Square
                        </h3>
                        <img src={Pukehoke}
                            className="mt-2 w-100" alt="Pukekohe Town Square" />
                        <p className="mt-3">
                            Pukekohe's King Street is adorned with charming cherry blossom trees,
                            creating a picturesque and enchanting scene that's not to be missed.
                        </p>
                        <hr />
                        <h2 className="fw-bold">Wellington</h2>
                        <hr />
                        <h3 className="mt-2">Wellington Botanic Garden</h3>
                        <p>
                            Explore the Lady Norwood Rose Garden and enjoy the cherry blossoms in this
                            stunning botanical garden.
                        </p>
                        <h3>
                            Blossom Valley, Aston Norwood Gardens
                        </h3>
                        <img src={Aston}
                            className="mt-2 w-100" alt="Aston Norwood Gardens" />
                        <p className="mt-3">
                            Located in the Aston Norwood Gardens, Blossom Valley boasts New Zealand's highest number and
                            density of mature cherry blossom trees. It's a must-visit destination for cherry blossom enthusiasts.
                        </p>
                        <p>
                            Experience the enchanting Blossom Valley at Aston Norwood where you can spend an evening
                            strolling along beautifully illuminated pathways and gardens, allowing you to witness the
                            blossoms in a whole new light. As you wander through this picturesque setting, you'll be
                            captivated by the stunning display of cherry blossoms.
                            But that's not all; Blossom Valley offers an array of culinary delights.
                            With an assortment of food and beverage options available, you can enjoy a delightful
                            picnic not only beneath the cherry blossoms but also under the starry night sky. It's
                            the perfect setting to savor both nature's beauty and delectable treats.
                        </p>
                        <hr />
                        <h2 className="fw-bold">Dunedin</h2>
                        <hr />
                        <h3 className="mt-2">Otago University Campus</h3>
                        <img src={Otago}
                            className="mt-2 w-100" alt="Otago University Campus" />
                        <p className="mt-3">
                            The Otago University campus is adorned with cherry blossoms, creating a
                            captivating spectacle each spring.
                        </p>

                        <hr />
                        <h2 className="fw-bold">Christchurch</h2>
                        <hr />
                        <h3 className="mt-2">Hagley Park</h3>
                        <img src={HagleyPark}
                            className="mt-2 w-100" alt="Hagley Park" />
                        <p className="mt-3">
                            The expansive Hagley Park showcases cherry blossoms in full bloom, providing a peaceful
                            atmosphere for a leisurely stroll.
                        </p>
                        <h3 className="mt-2">Botanic Garden</h3>
                        <img src={ChristChurchBotaniGardens}
                            className="mt-2 w-100" alt="Hagley Park" />
                        <p className="mt-3">
                            The Christchurch Botanic Gardens in New Zealand offer free entry and convenient parking options.
                            Visitors can utilize two large public car parks located at the Riccarton Avenue entrance and
                            the Armagh Street entrance, allowing up to 180 minutes of free parking.
                            Additionally, there are designated disability car parks, with four in the Riccarton car
                            park and five in the Armagh car park. To use these spaces, mobility cards must be displayed
                            in the vehicles. Moreover, the Armagh Street car park features an electric car charger.
                            For visitors with limited mobility, the Gardens provide three mobility scooters free of charge,
                            which can be used within the Gardens and Hagley Park during Visitor Centre opening hours.
                            However, it's important to note that dogs are not allowed in the Christchurch Botanic Gardens.
                            These facilities and accessibility options make the gardens a welcoming and inclusive destination for all.
                        </p>
                        <h3 className="mt-2">University of Canterbury</h3>
                        <img src={Canterbury}
                            className="mt-2 w-100" alt="University of Canterbury" />
                        <p className="mt-3">
                            The cherry blossoms on the university campus and in Christchurch city create a picturesque
                            and serene atmosphere. The University of Canterbury's Japanese program even hosts an annual hanami,
                            a traditional Japanese flower-viewing event, to celebrate the blooming cherry blossoms.
                        </p>

                        <hr />
                        <p>
                            These locations provide enchanting opportunities to witness the breathtaking beauty of cherry blossoms in
                            New Zealand, making spring the perfect time to plan your visit.
                        </p>

                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                            <h5 className="mt-3">
                                Related Products
                                <FontAwesomeIcon icon={faCircleInfo} className="small ms-1" style={{ color: "#494b50" }}
                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-custom-class="custom-tooltip"
                                    data-bs-title="As an Amazon Associate, I earn from qualifying purchases." />
                            </h5>
                            <a href="https://amzn.to/46PmpG4"><img src={Dress1} className="w-100 mt-3" alt=""></img></a>
                            <a href="https://amzn.to/46xF9KC"><img src={Dress2} className="w-100 mt-3" alt=""></img></a>
                            <a href="https://amzn.to/3S2wU4z"><img src={Dress3} className="w-100 mt-3" alt=""></img></a>
                            <a href="https://amzn.to/3QjZb5x"><img src={Dress4} className="w-100 mt-3" alt=""></img></a>
                            <a href="https://amzn.to/3M5IA2Z"><img src={Dress5} className="w-100 mt-3" alt=""></img></a>
                            <a href="https://amzn.to/48YRCIF"><img src={Dress6} className="w-100 mt-3" alt=""></img></a>
                            <a href="https://amzn.to/45Bvxx7"><img src={Tripod} className="w-100 mt-3" alt=""></img></a>
                            {/* <hr />
                            <p className="fst-italic">Disclosure: As an Amazon Associate, I earn from qualifying purchases.</p>
                            <hr /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default NzSpring2023;

export const title = "Embracing the Beauty of Spring in New Zealand";
export const shortParagraph = "Spring in New Zealand is a magical time when nature awakens from its winter slumber, painting the landscape with vibrant colors and new life. From September to November, the country bursts into a symphony of blossoms and lush greens, offering a plethora of activities and experiences for both locals and travelers. Today we'll take you on a journey through the enchanting world of New Zealand's spring.";
export const date = "17 October 2023";
export const image = Spring4;
export const url = "newzealand-cherry-blossom-2023";