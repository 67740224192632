import React from "react";
import BlogPostBanner from "../pages/BlogPostBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ShareToSocialMedia from "../pages/ShareToSocialMedia";

function RegionalParkInfo(props) {
    return (
        <section className="regionalparkinfo">
            <BlogPostBanner
                banner={props.banner}
                url={props.url}
                photocredits={props.photocredits} />
            <div className="container blog-text">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/regional-parks" className="blue-text">Regional Parks</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {props.title}
                                </li>
                            </ol>
                        </nav>
                        <h1 className="mt-3">{props.title}</h1>
                        <p>{props.shortParagraph}</p>
                        {
                            props.additionalInfo ? (
                                <p>{props.additionalInfo}</p>
                            ) :
                                (
                                    null
                                )

                        }
                        {
                            props.howtoreach ? (
                                <div>
                                    <h2 className="blue-text">How to Reach</h2>
                                    <p>{props.howtoreach}</p>
                                </div>
                            ) :
                                (
                                    null
                                )

                        }
                        {
                            props.tips ? (
                                <div>
                                    <h2 className="blue-text">Tips</h2>
                                    <p>{props.tips}</p>
                                </div>
                            ) :
                                (
                                    null
                                )

                        }

                        {
                            props.address ? (
                                <div>
                                    <h2 className="blue-text">Directions</h2>
                                    <p><FontAwesomeIcon icon={faLocationDot} className="me-2" /> {props.address}</p>
                                    <p><a href={props.location} className="text-decoration-none" target="_blank">View on Google Maps</a></p>
                                </div>
                            ) : (
                                null
                            )
                        }

                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            {/* <h5>Opening Hours</h5> */}

                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RegionalParkInfo;