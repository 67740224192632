import React from "react";
import Sky from "../assets/images/pexels-rafael-cerqueira-4737484.jpg";

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark border-body" style={{ backgroundImage: `url(${Sky})`, zIndex: "1" }}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/">A SILENT EXPLORER</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Destinations
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end nav-dropdown-menu" style={{ backgroundImage: `url(${Sky})` }}>
                                <div className="d-lg-flex">
                                    <div className="col p-2">
                                        <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/north-island">North Island</a></li>
                                        {/* <li><hr className="dropdown-divider" /></li>
                                        <li>
                                            <a className="dropdown-item nav-dropdown-item" href="#">Auckland</a>
                                        </li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Bay of Plenty</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">The Coromandel</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Hawke's Bay</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Manawatū</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Northland & Bay of Islands</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Rotorua</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Ruapehu</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Tairawhiti</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Taranaki</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Taupō</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Waikato</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Wairarapa</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Wellington</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Whanganui</a></li> */}
                                    </div>
                                    <div className="vr vr-blurry text-white"></div>
                                    <div className="col p-2">
                                        <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/south-island">South Island</a></li>
                                        {/* <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Central Otago</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Christchurch - Canterbury</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Clutha</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Dunedin</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Fiordland</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Marlborough</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Nelson Tasman</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Queenstown</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Southland</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Waitaki</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Wānaka</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">West Coast</a></li> */}
                                    </div>
                                    <div className="vr vr-blurry text-white"></div>
                                    <div className="col p-2">
                                        <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/other-islands">Other Islands</a></li>
                                        {/* <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Chatham Islands</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Stewart Island - Rakiura</a></li>
                                        <li><a className="dropdown-item nav-dropdown-item" href="#">Subantarctic Islands</a></li> */}

                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Attractions
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end nav-dropdown-menu" style={{ backgroundImage: `url(${Sky})` }}>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/regional-parks">Regional Parks</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end nav-dropdown-menu" style={{ backgroundImage: `url(${Sky})` }}>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/services/wagecalculator">WageCalculator</a></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Shop
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end nav-dropdown-menu" style={{ backgroundImage: `url(${Sky})` }}>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/shop/calendar2024">New Zealand Calendar 2024</a></li>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/shop/calendar2024_1">New Zealand Calendar 2024 1</a></li>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/shop/calendar2024_2">New Zealand Calendar 2024 2</a></li>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/shop/calendar2024_3">New Zealand Calendar 2024 3</a></li>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/shop/calendar2024_Img">Calendar 2024 Img</a></li>
                            </ul>
                        </li> */}

                        {/* <li className="nav-item">
                            <a className="nav-link" aria-disabled="true">Events</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-disabled="true">Transport</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-disabled="true">Accomodation</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-disabled="true">Deals and Packages</a>
                        </li> */}
                        <li className="nav-item dropdown">
                            <a className="nav-link  dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Articles
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end nav-dropdown-menu" style={{ backgroundImage: `url(${Sky})` }}>
                                <li><a className="dropdown-item nav-dropdown-item" href="/destinations/newzealand/travel-guide">Travel Guide</a></li>
                                <li><a className="dropdown-item nav-dropdown-item" href="https://travel.thebearsenal.com/">Archive</a></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="/nz/destination" aria-disabled="true">Destination</a>
                        </li> */}
                        {/* <li className="nav-item">
                            <a className="nav-link" aria-disabled="true">Resources</a>
                        </li> */}
                    </ul>
                    {/* <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-light" type="submit">Search</button>
                    </form> */}
                </div>
            </div>
        </nav >
    );
}

export default Navbar;