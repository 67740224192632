import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dp from "../assets/images/ase.jpg";
import { faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons';

function BlogPostInfo(props) {
    return (
        <React.Fragment>
            <h1 className="mt-3">
                {props.title}
            </h1>
            <hr />
            <div className="d-flex flex-lg-row flex-column justify-content-start rounded-5">
                <div className="p-2 text-center">
                    <span>
                        <img src={Dp}
                            className="rounded-circle me-2" alt="asilentexplorer" style={{ height: "40px" }}></img>
                        by
                        <a href="https://www.instagram.com/asilentexplorer/" className="text-reset ms-1">asilentexplorer</a>
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="p-2">
                        <span><FontAwesomeIcon icon={faCalendarDays} className="me-2" />{props.date}</span>
                    </div>
                    <div className="p-2">
                        <span><FontAwesomeIcon icon={faClock} className="me-2" />{props.time} mins read</span>
                    </div>
                </div>
            </div>
            <hr />
            <p className="mt-3 blog-para">
                {props.shortParagraph}
            </p>
        </React.Fragment>
    );
}

export default BlogPostInfo;