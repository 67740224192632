import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook, faLinkedin, faReddit, faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons';

function ShareToSocialMedia() {
    useEffect(() => {
        const link =  encodeURI(window.location.href);
        const msg = encodeURIComponent('Hey, I found this article helpful! Check it out!');
        const title = encodeURIComponent(document.querySelector('title').textContent);

        const fb = document.querySelector('.facebook');
        fb.href = `https://www.facebook.com/sharer/sharer.php?u=${link}`;

        const twitter = document.querySelector('.twitter');
        twitter.href = `http://twitter.com/share?&url=${link}&text=${msg}&hashtags=spring,cherryblossom,newzealand`;

        const linkedIn = document.querySelector('.linkedin');
        linkedIn.href = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;

        const reddit = document.querySelector('.reddit');
        reddit.href = `http://www.reddit.com/submit?url=${link}&title=${title}`;

        const whatsapp = document.querySelector('.whatsapp');
        whatsapp.href = `https://api.whatsapp.com/send?text=${msg}: ${link}`;

        const telegram = document.querySelector('.telegram');
        telegram.href = `https://telegram.me/share/url?url=${link}&text=${msg}`;

    }, []);

    return (
        <div className="sharetosocialmedia">
            <span className="mb-3">
                <h5>Share this story</h5>
            </span>
            <div id="share-buttons" className="d-flex flex-wrap justify-content-between mt-2">

                {/*********** facebook ***********/}
                <a className="facebook p-2" target="blank">
                    <FontAwesomeIcon icon={faFacebook} style={{ color: "#3b5998", fontSize: "30px" }} />
                </a>

                {/*********** twitter ***********/}
                <a className="twitter p-2" target="blank">
                    <FontAwesomeIcon icon={faTwitter} style={{ color: "#55acee", fontSize: "30px"  }} />
                </a>

                {/*********** linkedin ***********/}
                <a className="linkedin p-2" target="blank">
                    <FontAwesomeIcon icon={faLinkedin} style={{ color: "#0077b5", fontSize: "30px"  }} />
                </a>

                {/*********** reddit ***********/}
                <a className="reddit p-2" target="blank">
                    <FontAwesomeIcon icon={faReddit} style={{ color: "#cb2027", fontSize: "30px"  }} />
                </a>

                {/*********** whatsapp ***********/}
                <a className="whatsapp p-2" target="blank">
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#25D366", fontSize: "30px"  }} />
                </a>

                {/*********** telegram ***********/}
                <a className="telegram p-2" target="blank">
                    <FontAwesomeIcon icon={faTelegram} style={{ color: "#229ED9", fontSize: "30px"  }} />
                </a>

            </div>
        </div>
    );
}

export default ShareToSocialMedia;