import React from "react";
import Hiking from "../assets/images/pexels-yaroslav-shuraev-4510992.jpg";
import Surfing from "../assets/images/pexels-oliver-sjöström-1650732.jpg";
import Bungee from "../assets/images/pexels-dmitrii-eremin-11440267.jpg";
import Adventure from "../assets/images/canva-edit-1.png";
import Rafting from "../assets/images/caituna.jpg";

function Adventures() {
    return (
        <section className="adventures my-2">
            <div className="banner-image text-white text-center p-5"
                style={{
                    background: `url(${Adventure})`, backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", backgroundPosition: "center", height:"500px"
                }}>
            <div className="banner-overlay"></div>
            <div className="container">
                <h1 className="text-white fw-bold">Find Your Adventure</h1>
                <p className="mt-4 text-white text-center disable-text-selection">
                New Zealand is a haven for adventure seekers. From heart-pounding extreme sports to breathtaking natural wonders, 
                here is your gateway to the adrenaline-fueled experiences that await you in the Land of the Long White Cloud. 
                Dive into the ultimate adventure sports that only New Zealand can offer, and find your perfect thrill.
                </p>
                </div>
            </div>
            {/* <img src={Adventure} className="w-100" 
            alt="Photo by Andrei Tanase: https://www.pexels.com/photo/man-standing-on-a-rock-1271619/" /> */}
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-3 mb-3">
                        <div className="card shadowed-card text-bg-dark p-0 h-100">
                            <img src={Hiking} className="card-img h-100" alt="Photo by Yaroslav Shuraev:https://www.pexels.com/photo/photo-of-woman-sitting-on-rock-4510992/" />
                            <div className="card-img-overlay card-dark-overlay">
                                <h2 className="card-title text-center kaushan-text">Hiking</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <div className="card shadowed-card text-bg-dark p-0 h-100">
                            <img src={Surfing} className="card-img h-100" alt="Photo by Oliver Sjöström: https://www.pexels.com/photo/man-surfing-on-ocean-waves-1650732/" />
                            <div className="card-img-overlay card-dark-overlay">
                                <h2 className="card-title text-center kaushan-text">Surfing</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <div className="card shadowed-card text-bg-dark p-0 h-100">
                            <img src={Rafting} className="card-img h-100" alt="Image" />
                            <div className="card-img-overlay card-dark-overlay">
                                <h2 className="card-title text-center kaushan-text">Whitewater Rafting</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <div className="card shadowed-card text-bg-dark p-0 h-100">
                            <img src={Bungee} className="card-img h-100" alt="Photo by Dmitrii Eremin : https://www.pexels.com/photo/person-bungee-jumping-over-valley-11440267/" />
                            <div className="card-img-overlay card-dark-overlay">
                                <h2 className="card-title text-center kaushan-text">Bungee Jumping</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Adventures;