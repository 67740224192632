import React, { useEffect } from "react";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function AutumnInNz() {
    useEffect(() => {
        document.title = 'Autumn in New Zealand';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="John Adamson" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Autumn in New Zealand
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 ">
                            Here's a guide to experiencing the beauty of autumn in New Zealand:
                        </p>
                        <h3 className="blue-text">Colors of Foliage</h3>
                        <p>
                            Witness a breathtaking spectacle as lush green landscapes transition into a kaleidoscope of red,
                            orange, and gold. The deciduous trees, such as maples and liquidambars, paint the scenery with warm tones, creating a picturesque backdrop.
                        </p>

                        <h3 className="blue-text">Temperature and Weather</h3>
                        <p>
                            Autumn in New Zealand, spanning from March to May, brings cooler temperatures, making it
                            an ideal time for exploration. With mild weather and fewer crowds, it's the perfect season for outdoor activities.
                        </p>

                        <h3 className="blue-text">Harvest Festivals</h3>
                        <p>
                            Celebrate the abundance of the season by attending local harvest festivals. Experience the joy of grape harvesting in wine regions,
                            indulge in fresh produce at farmers' markets, and participate in community events that showcase the region's agricultural richness.
                        </p>

                        <h3 className="blue-text">Outdoor Adventures</h3>
                        <p>
                            Explore New Zealand's stunning landscapes adorned with autumnal hues. From hiking trails to scenic drives,
                            the country offers numerous opportunities to immerse yourself in the beauty of the season.
                        </p>

                        <h3 className="blue-text">Cozy Retreats</h3>
                        <p>
                            As the evenings become cooler, embrace the cozy ambiance of New Zealand's accommodations.
                            Enjoy a stay in boutique lodges or charming cottages, complete with fireplaces and stunning views.
                        </p>

                        <h3 className="blue-text">Local Cuisine</h3>
                        <p>
                            Autumn brings a bounty of fresh produce, influencing the local cuisine. Indulge in seasonal delights
                            like feijoa, pumpkins, and apples, as chefs craft delectable dishes inspired by the harvest.
                        </p>

                        <p>
                            Autumn in New Zealand is not merely a change in weather; it's a transformation of the landscape into
                            a canvas of colors and experiences waiting to be explored.
                        </p>


                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default AutumnInNz;

export const title = "Autumn Colors in New Zealand";
export const shortParagraph = "As summer bids adieu, New Zealand transforms into a mesmerizing tapestry of autumnal hues, inviting locals and travelers to savor the unique charm of this season.";
export const date = "22 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipO1YvdyP1vh6rlG5IG4jPqRwXJo8EGbv3LaMzSz=s1360-w1360-h1020";
export const url = "autumn-in-newzealand";