import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function DuderRegionalPark() {
    return (
        <section className="duderregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default DuderRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Duder Regional Park";
export const shortParagraph = "";
export const date = "25 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipNFL9SDEs_Z37d61J2Og1Lxy8WPrn8Z09q6jUPk=s1360-w1360-h1020";
export const photocredits = "Craig Loynes";
export const url = "duder-regional-park";
export const location = "https://www.google.com/maps/dir//duder+regional+park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d72b70d63eee8e7:0xa77e58c6feb6fd02?sa=X&ved=2ahUKEwj0uv_x-d2CAxXkZmwGHR_qCzEQ9Rd6BAhmEAA&sqi=2";
export const address = "933R North Road, Clevedon 2582";
export const website = "";
export const cordinates = [-36.90570391591614, 175.08414840027072];