import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function MuriwaiRegionalPark() {
    return (
        <section className="muriwairegionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default MuriwaiRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Muriwai Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOCV76OzMUxwoMYdU3QF76toysR4GZzvBe9JPDI=s1360-w1360-h1020";
export const photocredits = "Pavel Kotlarsky";
export const url = "muriwai-regional-park";
export const location = "https://www.google.com/maps/place/Muriwai+Regional+Park/@-36.8287982,174.4263921,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0d0d15f70df4b5:0x9513ef80e294abc!8m2!3d-36.8288025!4d174.428967!16s%2Fg%2F11dx91y23x?entry=ttu";
export const address = "Muriwai 0881";
export const website = "";
export const cordinates = [-36.82863071836773, 174.42902064032492];