import React, { useEffect } from "react";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function SpringInNz() {
    useEffect(() => {
        document.title = 'Spring in New Zealand';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="Dane Garrett" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Spring in New Zealand
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 ">
                        Here's a guide to fully immerse yourself in the beauty of the season:
                        </p>
                        <h3 className="blue-text">1. Hamilton Gardens, Waikato</h3>
                        <p>
                        Discover the enchanting Hamilton Gardens, a botanical paradise showcasing themed gardens that come to life with vibrant blooms in spring.
                        </p>

                        <h3 className="blue-text">2. Hagley Park, Christchurch - Canterbury</h3>
                        <p>
                        Hagley Park transforms into a kaleidoscope of colors with cherry blossoms and tulips. Enjoy 
                        a leisurely stroll or a picturesque picnic in this stunning urban oasis.
                        </p>


                        <h3 className="blue-text">3. Tongariro National Park</h3>
                        <p>
                        Spring is an ideal time for hiking in Tongariro National Park. Explore the diverse landscapes, from volcanic 
                        craters to emerald lakes, as nature awakens from winter's slumber.
                        </p>

                        <h3 className="blue-text">4. Lake Tekapo</h3>
                        <p>
                        Visit Lake Tekapo for breathtaking views of lupin-covered landscapes against the backdrop of the 
                        Southern Alps. Spring offers the perfect conditions for stargazing and capturing the iconic Church of the Good Shepherd.
                        </p>

                        <h3 className="blue-text">5. Queenstown</h3>
                        <p>
                        Thrill-seekers can embrace spring by exploring Queenstown's adventure activities. From bungee jumping to 
                        jet boating, experience an adrenaline rush against the backdrop of stunning alpine scenery.
                        </p>

                        <h3 className="blue-text">6. Spring Festivals</h3>
                        <p>
                        Engage in local spring festivals celebrating art, culture, and food. These festivals showcase New Zealand's 
                        creativity and are a perfect way to connect with the community
                        </p>

<p>Spring in New Zealand is a symphony of colors and experiences, offering a myriad of activities to suit every traveler's taste</p>


                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default SpringInNz;

export const title = "Experience the Vibrant Spring in New Zealand";
export const shortParagraph = "As winter's chill retreats, New Zealand blossoms into a captivating landscape during spring.";
export const date = "22 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMm5ZAj4YJjSnzXVbeW-a7R2cz2Al9hBg5tgA2Z=s1360-w1360-h1020";
export const url = "spring-in-newzealand";