import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Tawharanui() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Tawharanui;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Tāwharanui Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipPwyQlZDhZcxoh83Pt2ukQMBYUVjDrlN3NdaVZF=s1360-w1360-h1020";
export const photocredits = "Nikolai Vakhroushev";
export const url = "tawharanui-regional-park";
export const location = "https://www.google.com/maps/place/Tawharanui+Regional+Park/@-36.3715156,174.8272604,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0cd10a5ce71f0f:0x3b5c1e04f4668327!8m2!3d-36.3715199!4d174.8298353!16s%2Fg%2F11cs6hpz7v?entry=ttu";
export const address = "1181 Takatu Road, Tāwharanui Peninsula 0986";
export const website = "";
export const cordinates = [-36.37135573618847, 174.8298996691358];