import React from "react";
import Coromandel from "../assets/images/coromandel.jpg";
import Milford from "../assets/images/milford-sound.jpg";
import Chatham from "../assets/images/Chatham_Header-2000x1331.jpg";

function ChooseYourDestination() {
    return (
        <section className="chooseyourdestination">
            <div className="choosedestination">
                <div className="container py-5">
                    <h1 className="blue-text text-uppercase text-center">Choose Your Destination</h1>
                    <p className="mt-4 text-muted text-center disable-text-selection">
                        Escape the crowds and uncover the secrets of Aotearoa, the Land of the Long White Cloud.
                        Venture off the well-trodden paths to explore off-the-beaten-track destinations that
                        reveal the true essence of New Zealand. Explore the North and South Islands,
                        each with its own unique charm, and create memories that will last a lifetime
                        in this land of endless wonder.
                    </p>
                    <div className="row mt-5">
                        <div className="col-lg-4 mb-4">
                            <a href="/destinations/newzealand/north-island">
                                <div className="card shadowed-card text-bg-dark p-0 h-100" style={{ background: "rgba(255, 255, 255, 0.5)" }}>
                                    <img src={Coromandel} className="card-img h-100" alt="Image" />
                                    <div className="card-img-overlay">
                                        <h5 className="card-title text-center">North Island</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <a href="/destinations/newzealand/south-island">
                                <div className="card shadowed-card text-bg-dark p-0 h-100" style={{ background: "rgba(255, 255, 255, 0.5)" }}>
                                    <img src={Milford} className="card-img h-100" alt="Image" />
                                    <div className="card-img-overlay">
                                        <h5 className="card-title text-center">South Island</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <a href="/destinations/newzealand/other-islands">
                                <div className="card shadowed-card text-bg-dark p-0 h-100" style={{ background: "rgba(255, 255, 255, 0.5)" }}>
                                    <img src={Chatham} className="card-img h-100" alt="Image" />
                                    <div className="card-img-overlay">
                                        <h5 className="card-title text-center">Other Islands</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default ChooseYourDestination;