import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function TeRauPuriri() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default TeRauPuriri;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Te Rau Pūriri Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOKTpcp2y222--6YALyagm0pV8BTEioPyvvT6z0=s1360-w1360-h1020";
export const photocredits = "Ricky Muljana";
export const url = "te-rau-puriri-regional-park";
export const location = "https://www.google.com/maps/place/Te+Rau+Puriri+Regional+Park/@-36.492623,174.2524067,17z/data=!3m1!4b1!4m6!3m5!1s0x6d0daa94cd25ad83:0x2cefac629d4ca24b!8m2!3d-36.4926273!4d174.2549816!16s%2Fg%2F11cnphmfqm?entry=ttu";
export const address = "2911 South Head Road, South Head 0874";
export const website = "";
export const cordinates = [-36.49242889087893, 174.25507815564924];