import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faFacebook, faInstagram, faTiktok, faPinterest } from '@fortawesome/free-brands-svg-icons';
import Ocean from "../assets/images/pexels-pok-rie-1031200.jpg";

function Footer() {
    return (
        <footer className="text-center text-lg-start text-white" style={{ backgroundImage: `url(${Ocean})` }}>
            <div className="container p-4 pb-0 text-center text-md-start">
                <div className="row mt-3">
                    <div className="col-lg-4 text-center mb-4 mb-lg-0">
                        <h6 className="text-uppercase fw-bold">a silent explorer</h6>
                        {/* <p className="">
                            Journeying Through Aotearoa.
                        </p> */}
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <h6 className="text-uppercase fw-bold mb-4 text-center text-lg-start">About Us</h6>
                        <p className="text-center text-lg-start">
                            <a href="/theteam" className="text-white text-decoration-none">
                                The Team
                            </a>
                        </p>
                        <p className="text-center text-lg-start">
                            <a href="/values" className="text-white text-decoration-none">
                                Mission & Vision
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-4 mx-auto mb-4 mb-lg-0">
                        <h6 className="text-uppercase mb-4 fw-bold text-center text-lg-start">CONNECT WITH US</h6>
                        <div className="text-center text-lg-start">
                            {/* <h6 className="fw-bold">New Zealand</h6> */}
                            <p className="text-center text-lg-start">
                                <FontAwesomeIcon icon={faHouse} style={{ marginRight: "8px" }} /> Auckland, New Zealand</p>
                            <p className="text-center text-lg-start">
                                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} /> silentexplorernz@gmail.com</p>
                        </div>
                        {/* <div className="text-center mb-4 mb-lg-0 text-lg-start">
                            <h6 className="fw-bold">India</h6>
                            <p className="text-center text-lg-start">
                                <FontAwesomeIcon icon={faHouse} style={{ marginRight: "8px" }} /> Kerala, India</p>
                            <p className="text-center text-lg-start">
                                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} /> silentexplorer.in@gmail.com</p>
                        </div> */}
                    </div>
                </div>
            </div>


            <ul className="list-unstyled list-inline social text-center text-white">
                <li className="list-inline-item"><a href="https://www.facebook.com/asilentexplorer/"
                    className="btn btn-link btn-lg text-white m-1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} /></a></li>
                <li className="list-inline-item"><a href="https://www.tiktok.com/@asilentexplorer"
                    className="btn btn-link btn-lg text-white m-1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTiktok} /></a></li>
                <li className="list-inline-item"><a href="https://www.pinterest.nz/asilentexplorer/"
                    className="btn btn-link btn-lg text-white m-1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faPinterest} /></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/asilentexplorer/"
                    className="btn btn-link btn-lg text-white m-1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} /></a></li>
                <li className="list-inline-item"><a href="https://www.youtube.com/@asilentexplorer"
                    className="btn btn-link btn-lg text-white m-1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} /></a></li>
            </ul>

            <div className="text-center p-3">
                Powered by <a className="text-white text-decoration-none" href="http://ajeck.co.nz" target="_blank" rel="noopener noreferrer">Ajeck Systems Limited</a>
            </div>

            <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                © 2023 Copyright: <a className="text-white text-decoration-none" href="/">asilentexplorer.com</a>
                <a href="/legal/privacy-policy"
                    className="text-white text-decoration-none blog-text ms-2">
                    Privacy Policy</a> | <a href="/legal/terms-of-use" className="text-white text-decoration-none blog-text">
                    Terms of Use</a>
            </div>
        </footer>
    );
}

export default Footer;