import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function PapamoaHillsRegionalPark() {
    return (
        <section className="papamoahillsregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default PapamoaHillsRegionalPark;

export const tags = ["regionalpark", "bay-of-plenty", "north-island"];
export const title = "Papamoa Hills Regional Park";
export const shortParagraph = "Pāpāmoa Hills Regional Park is a cultural heritage site in the Bay of Plenty, New Zealand. Notable tracks include the Papamoa Hills Loop, a 3.3-mile trail providing panoramic views and the Papamoa Hill Summit Track.";
export const date = "16 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipNamcerSRs3lPIAjvjcXMea_Bo4TJVqy469rRf0=s1360-w1360-h1020";
export const photocredits="WengFai Chong";
export const url = "papamoa-hills";
export const location = "https://www.google.com/maps/dir//papamoa+hills+cultural+heritage+regional+park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d6e778fb2256c79:0xa99a4e3083bb0186?sa=X&ved=2ahUKEwjavevEhceCAxX2U2wGHaHvCbcQ9Rd6BAhYEAA&sqi=2";
export const address = "Poplar Lane, Papamoa 3187";
export const website = "";
export const cordinates = [-37.71025803668362, 176.29639362186208];