import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function WaimakaririRiverRegionalPark() {
    return (
        <section className="waimakariririverregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default WaimakaririRiverRegionalPark;

export const tags = ["regionalpark", "canterbury", "south-island"];
export const title = "Waimakariri River Regional Park";
export const shortParagraph = "Waimakariri River Regional Park is a scenic park in Canterbury, New Zealand, offering various outdoor activities. With diverse trails, including 13 mountain biking routes, the park caters to outdoor enthusiasts of different skill levels.  Known for its rushing waters, the Waimakariri River presents fishing options, adding to the park's appeal.";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipOYrVMU3ClvAETzTEisEfcvhxng6mJPMsW1kLo_=s1360-w1360-h1020";
export const photocredits="Ewald Kruger";
export const url = "waimakariri-river-regional-park";
export const location = "";
export const address = "";
export const website = "";
export const cordinates = [-43.450837338491475, 172.4424999412139];