import React, { useEffect } from "react";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function WinterInNz() {
    useEffect(() => {
        document.title = 'Winter in New Zealand';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="Thomas Quayle" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Winter in New Zealand
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 ">

                        </p>
                        <h3 className="blue-text">1. Alpine Adventures</h3>
                        <p>
                            Head to the Southern Alps for thrilling winter sports.
                            Queenstown and Wanaka beckon with world-class skiing and snowboarding, while the stunning scenery provides a picturesque backdrop.
                        </p>
                        <h3 className="blue-text">2. Hot Springs and Thermal Pools</h3>
                        <p>
                            Combat the chill by soaking in New Zealand's geothermal wonders. Rotorua and Taupo boast relaxing hot springs,
                            offering a perfect blend of warmth and natural beauty.
                        </p>

                        <h3 className="blue-text">3. Winter Festivals</h3>
                        <p>
                            Immerse yourself in the Kiwi winter spirit at festivals like the Queenstown Winter Festival.
                            Enjoy ice skating, live music, and the warmth of community celebrations.
                        </p>

                        <h3 className="blue-text">4. Cozy Retreats</h3>
                        <p>
                            Escape to a winter wonderland retreat. Boutique lodges and charming cabins offer the perfect setting
                            for snuggling up with a hot cocoa and enjoying the serenity of the snowfall.
                        </p>

                        <h3 className="blue-text">5. Glacial Explorations</h3>
                        <p>
                            Discover the beauty of New Zealand's glaciers. Take a guided tour to witness the breathtaking ice formations of Franz Josef and Fox Glacier.
                        </p>

                        <h3 className="blue-text">6. Winter Wildlife</h3>
                        <p>
                            Encounter unique winter wildlife experiences, from spotting seals on the Otago Peninsula to witnessing the playful antics of penguins in Oamaru.
                        </p>

                        <h2>What to pack for Winter</h2>

                        <p>
                            New Zealand's winter is a tapestry of adventure, relaxation, and natural beauty. Whether you're carving the slopes or enjoying a
                            serene hot spring, the Kiwi winter invites you to embrace the chill and create memories that will last a lifetime.
                        </p>


                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default WinterInNz;

export const title = "Embracing the Chill: A Winter Wonderland in New Zealand";
export const shortParagraph = "Winter in New Zealand transforms the landscape into a magical wonderland. From snow-capped mountains to cozy fireside retreats, the Kiwi winter offers a unique and enchanting experience for locals and visitors alike.";
export const date = "22 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOAZzHfiHICFSeGlYeG9ycovYwQX3g3RqDehv0U=s1360-w1360-h1020";
export const url = "winter-in-newzealand";