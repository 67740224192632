import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Tapapakanga() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Tapapakanga;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Tāpapakanga Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOd-UdMXQ10lp1NCEgTJh6BcqaSlfepdz98W333=s1360-w1360-h1020";
export const photocredits = "Terry Nichols";
export const url = "tapapakanga-regional-park";
export const location = "https://www.google.com/maps/place/Tapapakanga+Regional+Park/@-36.9802017,174.9293122,10z/data=!4m10!1m2!2m1!1sTapapakanga+Regional+Park!3m6!1s0x6d72bd99b4a98bc9:0xa0c32d472855f991!8m2!3d-36.9802017!4d175.258902!15sChlUYXBhcGFrYW5nYSBSZWdpb25hbCBQYXJrWhsiGXRhcGFwYWthbmdhIHJlZ2lvbmFsIHBhcmuSAQRwYXJrmgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVJOYlhZelJsUkJFQUXgAQA!16s%2Fg%2F1tlvpqpl?entry=ttu";
export const address = "95 Deerys Road, Ōrere Point 2585";
export const website = "";
export const cordinates = [-36.96209831333444, 175.26439515092352];