import React from "react";
import BlogPostBanner from "../pages/BlogPostBanner";
import RegionalParksReserves from "./island-info/RegionalParksIslandInfo";


function OtherIslands() {
    return (
        <section>
            <BlogPostBanner
                banner="https://lh5.googleusercontent.com/p/AF1QipPQX4QVWqbwN047uF5RYHGv_8eWd3J8T9cOPZSr=s1360-w1360-h1020"
                url="https://lh5.googleusercontent.com/p/AF1QipPQX4QVWqbwN047uF5RYHGv_8eWd3J8T9cOPZSr=s1360-w1360-h1020"
                photocredits="V Williams" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Destinations</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Other Islands
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <RegionalParksReserves
                island="other-islands"
            />
        </section>
    )
}

export default OtherIslands;