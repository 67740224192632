import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";
import PegasusBay from "../../assets/images/Bernard Spragg.jpg";

function NorthernPegasusBay() {
    return (
        <section className="waimakariririverregionalparkinfo">
            <RegionalParkInfo
                            banner={PegasusBay}
                            url={PegasusBay}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default NorthernPegasusBay;

export const tags = ["regionalpark", "canterbury", "south-island"];
export const title = "Northern Pegasus Bay";
export const shortParagraph = "Northern Pegasus Bay is a region in New Zealand, specifically in the Canterbury region. It encompasses areas like Waikuku, Pegasus Town, and Woodend, known for recreational activities such as swimming and surfing.";
export const date = "14 November 2023";
export const image = PegasusBay;
export const photocredits="Bernard Spragg";
export const url = "northern-pegasus-bay";
export const location = "";
export const address = "";
export const website = "";
export const cordinates = [-43.303601758054796, 172.711384933284];