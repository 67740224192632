import React from "react";
import RegionalParksReserves from "./island-info/RegionalParksIslandInfo";
import AppBackground from "../assets/images/coromandel.jpg";
import BlogPostBanner from "../pages/BlogPostBanner";


function NorthIsland() {
    return (
        <section>
            <BlogPostBanner
                banner="https://lh5.googleusercontent.com/p/AF1QipNTsu69sYnnvDyvAyrqpQnQqvppewCtMrMJMshg=s1360-w1360-h1020"
                url="https://lh5.googleusercontent.com/p/AF1QipNTsu69sYnnvDyvAyrqpQnQqvppewCtMrMJMshg=s1360-w1360-h1020"
                photocredits="TheCoromandel Goodforyoursoul" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Destinations</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    North Island
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <RegionalParksReserves
                island="north-island"
            />

        </section>
    )
}

export default NorthIsland;