import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function AtiuCreek() {
    return (
        <section className="atiucreekregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default AtiuCreek;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Ātiu Creek Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMdbL5hLf7LNGxCCE6BKFTFSN3VzLCC-b_9vgik=s1360-w1360-h1020";
export const photocredits = "Thomas Cheng";
export const url = "atiu-creek-regional-park";
export const location = "https://www.google.com/maps/dir//%C4%80tiu+Creek+Regional+Park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d0cfb294a415063:0x8d18e0270f80e1e7?sa=X&ved=2ahUKEwj9mcfGo9yCAxX8aPUHHYXPB6oQ9Rd6BAhBEAA";
export const address = "414 Run Road, Tapora 0977";
export const website = "";
export const cordinates = [-36.33075368031279, 174.38261691146235];