import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function ShakespearRegionalPark() {
    return (
        <section className="shakespearregionalparkinfo">
            <RegionalParkInfo
                            banner={image}
                            url={image}
                            photocredits={photocredits}
                            title={title}
                            shortParagraph={shortParagraph}
                            location={location}
                            address={address}
            />
        </section>
    );
}

export default ShakespearRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Shakespear Regional Park";
export const shortParagraph = "Shakespear Regional Park is a nature park in the Auckland Region of New Zealand. It is located at the tip of the Whangaparaoa Peninsula, and is named after the Shakespear family which bought the land in the 1880s from local Maori. The park includes the Tamaki Leadership Centre, a Royal New Zealand Navy base";
export const date = "14 November 2023";
export const image = "https://lh3.googleusercontent.com/p/AF1QipNDdPX0eExwAznLy6FX-ndw9o4Ar-CYxsnr8oRz=s1360-w1360-h1020";
export const photocredits="Vicky Xing";
export const url = "shakespear-regional-park";
export const location = "https://www.google.com/maps/dir//Shakespear+Regional+Park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d0d2ee6700e29bd:0xf00ef62249c9b10?sa=X&ved=2ahUKEwj20ZemwcKCAxVFslYBHYHHCDsQ9Rd6BAhtEAA";
export const address = "1501 Whangaparaoa Road, Army Bay, Auckland 0930";
export const website = "";
export const cordinates = [-36.606601234196226, 174.82462129613464];