import React, { useEffect } from "react";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function SummerInNz() {
    useEffect(() => {
        document.title = 'Summer in New Zealand';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={image}
                url={image}
                photocredits="Lauren Grattan" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Summer in New Zealand
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 ">

                        </p>
                        <h3 className="blue-text">1. Explore the Great Outdoors</h3>
                        <p>
                            Dive into nature with New Zealand's breathtaking landscapes. From the golden beaches of the Coromandel
                            to the alpine wonders of Queenstown, hiking, biking, and exploring are a must.
                        </p>
                        <h3 className="blue-text">2. Water Wonders</h3>
                        <p>
                            Cool off in style – try kayaking in the Abel Tasman, surfing in Raglan, or experiencing the magic of glowworm caves in Waitomo.
                        </p>

                        <h3 className="blue-text">3. Festivals and Events</h3>
                        <p>
                            Immerse yourself in the vibrant Kiwi culture with summer festivals. The Auckland Lantern Festival 
                            and Rhythm and Vines are just a taste of the lively events awaiting you.
                        </p>

                        <h3 className="blue-text">4. Culinary Delights</h3>
                        <p>
                            Indulge your taste buds in seasonal delights. From fresh seafood to world-class wines, New Zealand's culinary scene is a treat for food enthusiasts.
                        </p>

                        <h3 className="blue-text">5. Maori Cultural Experiences</h3>
                        <p>
                            Connect with the rich Maori heritage through cultural performances, traditional hangi feasts, and exploring historical sites.
                        </p>

                        <h2>What to pack for Summer</h2>

                        <p>
                            Make your New Zealand summer unforgettable by blending outdoor adventures, cultural experiences, and culinary delights.
                            So, pack your sunscreen and get ready to sizzle into the Kiwi summer – your ultimate sun-soaked adventure awaits!
                        </p>


                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default SummerInNz;

export const title = "The Kiwi Summer: A Guide to Fun and Sun in New Zealand";
export const shortParagraph = "Welcome to the land of the long white cloud, where summer is a symphony of sunshine and adventure! New Zealand offers a plethora of exciting activities to make the most of the warm summer months. Whether you're a thrill-seeker or someone who enjoys a leisurely day, there's something for everyone.";
export const date = "22 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipMhue_ZZuwsSzhqwd6BBwQ03_ZnbEqL70K4gRB6=s1360-w1360-h1020";
export const url = "summer-in-newzealand";