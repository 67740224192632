import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


function RegionalParksReserves(props) {
    const importAll = (r) => {
        return r.keys().map(r);
    };

    const regionalParks = importAll(require.context('../../destinationNZ/regionalparks', false, /\.js$/));

    const islandRegionalParks = regionalParks.filter((item) =>
        item.tags.includes(props.island)
    );

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            // slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            // slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            // slidesToSlide: 1 // optional, default to 1.
        }
    };

    if (islandRegionalParks.length > 0) {

        return (
            <section>
                <div className="islandinfo py-5">
                    <div className="container">
                        <h1 className="blue-text text-center text-uppercase">Regional Parks and Reserves</h1>

                        <Carousel
                            swipeable={true}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            // ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={4000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            // removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {
                                islandRegionalParks.map(
                                    islandRegionalPark => (
                                        <div className="p-2 position-relative" style={{ height: "90%" }} key={islandRegionalPark.url}>
                                            <a href={`/destinations/newzealand/regional-parks/${islandRegionalPark.url}`}>
                                                <div className="card text-center text-bg-dark mb-3 h-100" style={{ border: "none" }}>
                                                    <img src={islandRegionalPark.image} className="card-img h-100" alt={islandRegionalPark.title} />
                                                    <div className="card-img-overlay position-absolute top-0 start-0 end-0 bottom-0"
                                                        style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                                                        <h5 className="card-title">{islandRegionalPark.title}</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))
                            }
                        </Carousel>
                    </div>
                </div>
            </section>
        )
    } else {
        return null;
    }
}

export default RegionalParksReserves;