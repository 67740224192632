import React, { useEffect } from "react";
import AppBackground from "../../assets/images/pexels-erik-mclean-5158182.jpg";
import ShareToSocialMedia from "../ShareToSocialMedia";
import BlogPostInfo from "../BlogPostInfo";
import BlogPostBanner from "../BlogPostBanner";

function IndianLicenceConversion() {
    useEffect(() => {
        document.title = 'Converting Indian licence to New Zealand driver licence';
    }, []);

    return (
        <section>
            <BlogPostBanner
                banner={AppBackground}
                url="https://www.pexels.com/photo/luxury-sport-car-with-large-headlights-5158182/"
                photocredits="Erik Mclean" />
            <div className="container blog-text disable-text-selection">
                <div className="row">
                    <div className="col-lg-9">
                        <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/" className="blue-text">New Zealand</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/destinations/newzealand/travel-guide" className="blue-text">Travel Guide</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Class 1 Driving Licence Conversion
                                </li>
                            </ol>
                        </nav>
                        <BlogPostInfo title={title} shortParagraph={shortParagraph} date={date} time="5" />
                        <p className="mt-3 blog-para">
                            We'll walk you through the essential steps and provide you with all the information you
                            need to smoothly transition from your Indian licence to a New Zealand Class 1 (car) licence.
                        </p>
                        <h2>The Conversion Process</h2>
                        <h3 className="blue-text">Eligibility Check</h3>
                        <p>Ensure you meet the criteria for conversion, which typically includes being a
                            New Zealand resident or visitor and having a valid Indian driver's license.
                        </p>
                        <p>
                            In New Zealand, you're allowed to drive using an overseas driver's license for a
                            maximum period of 12 months from your last entry into the country. However, if
                            you've been in New Zealand for more than 12 months, you can no longer use your
                            overseas driver's license to drive legally. To continue driving in New Zealand
                            beyond this 12-month period, you must obtain a New Zealand driver's license. It's
                            essential to ensure that your license is current and valid during your stay in
                            New Zealand. So, if you plan to stay in the country for an extended period, make
                            sure to convert your overseas driver's license to a New Zealand license to comply
                            with local regulations and enjoy uninterrupted driving privileges.
                        </p>
                        <h3 className="blue-text">Gather Required Documents</h3>
                        <p>
                            Prepare your Indian driver's license, proof of identity, residency or visa status,
                            and any additional documents requested by the licensing authority.
                        </p>
                        <ul>
                            <li>A copy of your Indian passport along with the original passport as proof of your identity.</li>
                            <li>A copy of your current valid Indian driver's license along with the original license</li>
                            <li>Evidence of validity from <a 
                            href="https://sarathi.parivahan.gov.in/sarathiservice/stateSelection.do" 
                            target="_blank" rel="noopener noreferrer" className="blue-text">Parivahan</a></li>
                            <ul>
                                <li>Visit the official Parivahan website: Go to the <a
                                    href="https://parivahan.gov.in/" target="_blank" rel="noopener noreferrer" className="blue-text">Parivahan</a> website</li>
                                <li>Navigate to "Online Services": On the website, look for a section related to
                                    "Online Services" or "Driving License Services."
                                    It may be in the main menu or under a specific category.</li>
                                <li>Choose "Driving License"(DL) Services from the available online services.</li>
                                <li>Check License Validity: Look for an option to check your driving license validity.
                                    It may be labeled as "Check License Validity" or "Verify License."</li>
                                <li>Enter License Details: You will be prompted to enter your driving license number and
                                    other required details. Fill in the necessary information accurately.</li>
                                <li>Verify Captcha: Some services may require you to complete a CAPTCHA to confirm that you are not a bot.</li>
                                <li>Submit and View: After providing the required information, click the "Submit" or
                                    "Check" button. The system will process your request, and you should be able to view the validity
                                    status of your driving license.</li>
                                <li>Save or Print: If available, consider saving a digital copy of the validity status for your
                                    records or print it as evidence of your license validity.</li>
                            </ul>
                            <li>
                                Filled <a 
                                href="https://www.nzta.govt.nz/assets/resources/application-conversion-overseas-licence-dl5/docs/dl5.pdf" 
                                target="_blank" rel="noopener noreferrer" className="blue-text">application form</a>
                                . Application form can be obtained for free from any New Zealand Driver licensing agents(AA centers). </li>
                            <li>Medical certificate (if required)</li>
                        </ul>
                        <p>An IDP(international driving permit) is not a substitute for the physical license and serves primarily as
                            a translation of your existing license when you're driving in foreign countries. Make sure to have your
                            original driver's license with you as part of the documentation required for the conversion process</p>
                        <h3 className="blue-text">
                            Visit a Driver Licensing Agent
                        </h3>
                        <p>
                            Find a nearby Driver Licensing Agent(often an AA Center) and visit them during their operating hours. Bring
                            along all the required documents. The staff will verify your documents to ensure their authenticity and validity.
                            The agent will take your photograph and signature, which will be used for your New Zealand driver's license.
                            You may also be required to undergo an eye test at the Driver Licensing Agent's office. If you wear
                            contact lenses or glasses, be sure to bring them along for the eye test.
                        </p>
                        <h3 className="blue-text">Pay Conversion Fees</h3>
                        <p>
                            Be aware of the fees involved and ensure you have the necessary funds.
                            For the conversion process, you will be required to pay a full license conversion fee of NZD $144.60,
                            and you can make this payment using a card. This fee covers the entire cost of processing your license conversion.
                            It's advisable to check the most up-to-date fee information on official New Zealand <a
                                href="https://www.nzta.govt.nz/driver-licences/new-residents-and-visitors/converting-to-nz-driver-licence/#tests"
                                target="_blank" rel="noopener noreferrer" className="blue-text">
                                government websites</a> or contact the local Driver Licensing Agent for any changes in fees or payment methods.
                        </p>
                        <h3 className="blue-text">Pass a Theory Test</h3>
                        <p>
                            You need to take a theory test based on the New Zealand road code. You can learn and give
                            practice tests <a href="https://drive.govt.nz/learner-licence/interactive-road-code" 
                             target="_blank" rel="noopener noreferrer" className="blue-text">here</a>.
                            During the actual test, you must answer at least 32 questions correctly out of the total 35 to pass the exam.
                            You can book the theory test date at the AA center once you pay the conversion fees.
                        </p>
                        <p>
                            After successfully passing the theory test, you'll receive a New Zealand driver's
                            license with a supervisor condition. This New Zealand license takes precedence over
                            your overseas driver's license. Consequently, you must have a supervisor seated next
                            to you in the front seat while driving until you've completed your practical driving test.
                            A supervisor is a person who:
                        </p>
                        <ul>
                            <li>holds a current full New Zealand driver licence for the class you're learning to drive, and</li>
                            <li>doesn't have a supervisor condition on their New Zealand licence, and</li>
                            <li>has held a full New Zealand licence for at least 2 years OR has held an equivalent overseas driver licence for at least 2 years.</li>
                        </ul>
                        <h3 className="blue-text">Practical Driving Test</h3>
                        <p>
                            The AA center, where you paid the conversion fees and took the theory test, will
                            provide you with a temporary license slip. Within 21 days, you'll receive your physical
                            driver's license card at your home address.
                        </p>
                        <p>
                            To proceed with your practical driving test, you can book it through the AA center
                            or <a href="https://online.nzta.govt.nz/licence-test/identification" 
                            target="_blank" rel="noopener noreferrer" className="blue-text">online</a> using
                            your temporary driving license details. The good news is that you
                            can rebook the practical test any number of times without incurring additional costs,
                            offering flexibility and opportunities to pass your test.
                        </p>
                        {/* <p>We'll provide insights on what to expect on practical driving test.</p> */}
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-5 p-2">
                            <ShareToSocialMedia />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );

}

export default IndianLicenceConversion;

export const title = "A Comprehensive Guide to Converting Your Indian Driver's licence to a New Zealand licence";
export const shortParagraph = "Are you planning to embark on a new journey in New Zealand and need to convert your Indian driver's licence to a New Zealand licence? This guide has got you covered.";
export const date = "07 November 2023";
export const image = AppBackground;
export const url = "indian-licence-to-new-zealand-licence-conversion-guide";