import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NzTravelGuide() {
    const navigate = useNavigate();
    const navigateToUrl = (url) => {
        navigate(url);
    };

    // Function to import all blog pages from the folder
    const importAll = (r) => {
        return r.keys().map(r);
    };

    // Import all blog pages
    const blogPages = importAll(require.context('./travel-guides', false, /\.js$/));

    // Loop through imported blog pages and extract title and short paragraph
    const blogPosts = blogPages.map((page, index) => {
        const { title, shortParagraph, date, image, url } = page;
        return (
            <div key={index} className="">
                <div className="card mb-3 blog-card">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src={image} className="img-fluid rounded-start h-100 disable-text-selection" alt="New Zealand Travel Guide" style={{pointerEvents: "none"}} />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title fs-2" style={{color:"#087EA6"}}>{title}</h5>
                                <p className="card-text py-2">{shortParagraph}</p>
                                <p className="card-text"><small className="text-body-secondary">Posted on {date}</small></p>
                                <button type="button" className="btn btn-primary" onClick={() => navigateToUrl(url)}>Read more</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <section className="nztravelguide">
            <div className="container">
                <nav className="mt-5" aria-label="breadcrumb" style={{ breadcrumbDivider: "'>'" }}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/" className="blue-text">Home</a></li>
                        <li className="breadcrumb-item"><a href="/" className="blue-text">New Zealand</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Travel Guide</li>
                    </ol>
                </nav>
                {blogPosts}
            </div>
        </section>
    );
}

export default NzTravelGuide;