import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function Whakatiwai() {
    return (
        <section className="regionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default Whakatiwai;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Whakatīwai Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOtC7Gt0DzsUFZ60kS7dx7q4eS7Qg_zcAst49vz=s1360-w1360-h1020";
export const photocredits = "Mixer Og";
export const url = "whakatiwai-regional-park";
export const location = "https://www.google.com/maps/place/Whakanewha+Regional+Park/@-36.8132504,175.0709521,11z/data=!4m15!1m7!3m6!1s0x6d7298386f4bdeb9:0xf1d22e0cce39914c!2sWhakatiwai+Regional+Park!8m2!3d-37.0733368!4d175.2837715!16s%2Fg%2F11c7sy3wrl!3m6!1s0x6d72c845367db883:0xf00ef62249cb740!8m2!3d-36.8216896!4d175.0768473!15sChlXaGFrYXTEq3dhaSBSZWdpb25hbCBQYXJrWhsiGXdoYWthdMSrd2FpIHJlZ2lvbmFsIHBhcmuSAQRwYXJrmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVJUZWxsTWFUbDNSUkFC4AEA!16s%2Fg%2F1thvygkc?entry=ttu";
export const address = "75 Gordons Road, Waiheke Island, Auckland 1971";
export const website = "";
export const cordinates = [-36.810420682196415, 175.0768472880457];