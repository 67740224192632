import React, { useRef } from 'react';
import January from "./months_2/January";
import February from "./months_2/February";
import March from "./months_2/March";
import April from "./months_2/April";
import May from "./months_2/May";
import June from "./months_2/June";
import July from "./months_2/July";
import August from "./months_2/August";
import September from "./months_2/September";
import November from "./months_2/November";
import December from "./months_2/December";
import October from "./months_2/October";
import { pdfjs } from "react-pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Background from "../assets/images/pexels-jarod-barton-4863998.jpg";

function Calendar2024_4() {
    // Trying Canva
    const calendarRef = useRef(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


    const generateImgPDF = async () => {
        const input = calendarRef.current;
        const pdf = new jsPDF("p", "mm", "a4");

        // Use html2canvas to capture the content of the specified element
        const canvas = await html2canvas(input);

        // Add the captured image to the PDF
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 10, 10, 190, 277); // Adjust the position and dimensions as needed

        // Save the PDF
        pdf.save("calendar2024.pdf");
    };

    return (
        <section className="calendar2024">
            <div className="container mt-5">
                <div className="row">
                    <button className="btn btn-outline-secondary" onClick={generateImgPDF}>Print</button>
                </div>
            </div>

            <div className="container my-5 shadow" id="calendar" style={{ width: '595pt', height: '842pt', margin: 'auto', padding: '5mm', backgroundColor: '#fff' }} ref={calendarRef}>
                <div className="row mb-5" style={{ backgroundColor: "#087EA6" }}>
                    <img src={Background}
                        className="pt-5 px-5" alt="New Zealand Regional Parks"
                        style={{ pointerEvents: "none", position: "absolute", top: 0, left: 0 }} />
                </div>
            </div>

        </section>
    );
}

export default Calendar2024_4;