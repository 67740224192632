import React from "react";
import RegionalParkInfo from "../RegionalParkInfo";

function AwhituRegionalPark() {
    return (
        <section className="awhituregionalparkinfo">
            <RegionalParkInfo
                banner={image}
                url={image}
                photocredits={photocredits}
                title={title}
                shortParagraph={shortParagraph}
                location={location}
                address={address}
            />
        </section>
    );
}

export default AwhituRegionalPark;

export const tags = ["regionalpark", "auckland", "north-island"];
export const title = "Āwhitu Regional Park";
export const shortParagraph = "";
export const date = "24 November 2023";
export const image = "https://lh5.googleusercontent.com/p/AF1QipOWNFumPRK9-Zucpjb6IfV0SNZWd_7wMwHK923h=s1360-w1360-h1020";
export const photocredits = "Andrew Kenny";
export const url = "awhitu-regional-park";
export const location = "https://www.google.com/maps/dir//%C4%80whitu+Regional+Park/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6d0d5e81a8ae2461:0xf00ef62249d0a50?sa=X&ved=2ahUKEwjEqrysp9yCAxXcsVYBHdQyBh0Q9Rd6BAhHEAA";
export const address = "Brook Road, Āwhitu 2684";
export const website = "";
export const cordinates = [-37.09145636290962, 174.6522661969163];